<template>
  <modal class="eu-file-upload-modal" :isActiveProp="isModalActive" @close="closeFileModal">
    <div v-if="isLoading">
      <loading-spinner />
    </div>
    <div style="display: contents" v-else>
      <div
        v-if="!editFile"
        class="row"
        style="align-items: baseline"
        tabindex="0"
        id="upload-file-modal-title"
        aria-label="Upload file modal title."
      >
        <h2 class="eu-file-upload-modal-title">
          Upload file(s)
          <span
            tabindex="0"
            aria-label="Close modal"
            role="button"
            @click="closeFileModal()"
            @keyup.enter="closeFileModal()"
          >
            <i class="far fa-times eu-file-upload-modal-icon"></i>
          </span>
        </h2>
      </div>
      <div v-else class="title-container">
        Edit file details
        <button
          @click="closeFileModal()"
          @keyup.enter="closeFileModal()"
          class="close-button"
          aria-label="Close file modal"
        >
          <i class="far fa-times"></i>
        </button>
      </div>
      <div v-if="!editFile" style="display: contents">
        <div class="row" style="align-items: baseline; overflow: hidden">
          <span v-for="(file, i) in files" v-bind:key="file.name" style="position: relative; margin: 10px 0">
            <label
              v-if="file.file_type == 'IMG'"
              class="uploaded-image-preview"
              :style="{ backgroundImage: 'url(' + file.file_url + ')' }"
            ></label>
            <i v-if="file.file_type == 'DOC'" class="fas fa-file-upload uploaded-file-icon"></i>
            <span class="eu-upload-filename"> {{ file.name }} </span>
            <span class="eu-upload-status">({{ file.status }})</span>
            <span v-if="file.status == 'uploading'" class="eu-line eu-stripesLoaderAnimated"></span>
            <span
              v-if="file.status == 'pending'"
              class="eu-line eu-stripesLoader"
              style="background-position: 0"
            ></span>
            <span
              v-if="file.status == 'failed'"
              class="eu-line eu-stripesLoader"
              style="background-color: #e65722"
            ></span>
            <span
              tabindex="0"
              aria-label="edit file details"
              v-if="file.status != 'uploading'"
              class="eu-upload-edit-details"
              @click="editFileClicked(file.name)"
              @keyup.enter="editFileClicked(file.name)"
            >
              Edit details
            </span>
            <span
              tabindex="1"
              aria-label="Remove File"
              role="button"
              @click="removeFile(i)"
              @keyup.enter="removeFile(i)"
            >
              <i class="far fa-times eu-file-upload-modal-icon"></i>
            </span>
          </span>
        </div>

        <div class="row" style="align-items: baseline">
          <div class="column" style="width: 100%">
            <div v-cloak @drop.prevent="handleFileUpload" @dragover.prevent class="innerRow dnd-wrapper">
              <label
                @dragenter="dragging = true"
                @dragleave="dragging = false"
                class="file-upload-label"
                :style="{ background: dragging ? '#eaeeff' : '' }"
                for="resource_image"
              >
                <span>
                  <i class="fad fa-cloud-upload-alt dnd-upload-icon"></i> <br />
                  Drag &amp; drop files here <br />
                  or <br />
                  <input @change="handleFileUpload" id="resource_image" type="file" multiple />
                  <span class="file-input-text">
                    Browse from device <i class="fal fa-angle-right" style="color: #5e50b5; font-size: 15px"></i>
                  </span>
                </span>
              </label>
            </div>
          </div>
          <label
            id="acceptedFileTypes"
            class="eu-checkbox-container"
            style="padding-left: 0; margin-top: 25px; text-align: center; color: #707070"
            >Accepted file types: <br />
            <span style="font-weight: 500" aria-labelledby="acceptedFileTypes">
              .pdf, .doc, .xls, .ppt, .jpg, .png, .gif, .webp
            </span>
          </label>
        </div>
      </div>
      <form v-if="editFile" class="resource-form">
        <div class="small-dropzone-container" v-if="editFileObj && editFileObj.file_type !== 'IMG'">
          <small-dropzone-component
            id="file-upload"
            :imageSrc="editFileObj.thumbnail_url"
            :maxImageSize="2000000"
            :dialogDisplay="true"
            maxImageSizeFormat="2 MB"
            changeButtonText="Change Thumbnail"
            uploaderText="Add Resource Thumbnail"
            @uploadImage="handleUploadThumbnail"
            @removeImage="handleRemoveThumbnail"
          />
        </div>
        <input-base
          id="file-name"
          type="text"
          label="File Name"
          aria-label="Enter file name"
          maxlength="255"
          v-model="editFileObj.name"
          :required="true"
          :value="editFileObj.name"
        />
        <div class="two-inputs-row">
          <select-base
            v-if="!currentDepartment && !currentGroup && !isPartnerTenant"
            id="file-department"
            :label="`${deptSingular}`"
            :placeholderDisabled="true"
            :options="
              list_departments.map((e) => {
                return {
                  label: e.name,
                  value: e.name,
                };
              })
            "
            v-model="editFileObj.department"
            style="width: 100%"
            :aria-label="`Select ${deptSingular}`"
          >
          </select-base>
          <select-base
            id="file-type"
            label="Type/Label"
            :placeholderDisabled="true"
            :options="
              resourceLabelOptions.map((e) => {
                return {
                  label: e,
                  value: e,
                };
              })
            "
            v-model="editFileObj.label"
            style="width: 100%"
            aria-label="Select label"
          >
          </select-base>
        </div>
        <div class="dialog-multiselect-wrapper">
          <multiselect
            id="focus-area"
            v-model="editFileObj.focus_areas"
            :options="filterData.focus_areas.map((e) => e.name)"
            :multiple="true"
            :close-on-select="false"
            placeholder="Select option"
            :max="5"
          ></multiselect>
          <i class="fal fa-angle-down multiselect-arrow"></i>
          <label for="focus-area" class="multiselect-label">Focus Areas</label>
        </div>
        <div v-if="isPartnerTenant" class="dialog-multiselect-wrapper">
          <multiselect
            id="countries"
            v-model="editFileObj.countries"
            :options="filterData.countries.map((e) => e.name)"
            :multiple="true"
            :close-on-select="false"
            placeholder="Select option"
            :max="5"
          ></multiselect>
          <i class="fal fa-angle-down multiselect-arrow"></i>
          <label for="countries" class="multiselect-label">Destinations</label>
        </div>
        <div class="dialog-multiselect-wrapper">
          <multiselect
            id="identity"
            v-model="editFileObj.identities"
            :options="filterData.identities.map((e) => e.name)"
            :multiple="true"
            :close-on-select="false"
            placeholder="Select option"
            :max="5"
          ></multiselect>
          <i class="fal fa-angle-down multiselect-arrow"></i>
          <label for="focus-area" class="multiselect-label">Identities</label>
        </div>
        <div class="dialog-multiselect-wrapper">
          <multiselect
            id="audience"
            v-model="editFileObj.audiences"
            :options="filterData.audiences.map((e) => e.name)"
            :multiple="true"
            :close-on-select="false"
            placeholder="Select option"
            :max="5"
          ></multiselect>
          <i class="fal fa-angle-down multiselect-arrow"></i>
          <label for="focus-area" class="multiselect-label">Audiences</label>
        </div>
        <div v-if="isPartnerTenant" class="dialog-multiselect-wrapper">
          <multiselect
            id="cai"
            v-model="editFileObj.career_academic_interests"
            :options="filterData.career_academic_interests.map((e) => e.name)"
            :multiple="true"
            :close-on-select="false"
            placeholder="Select option"
            :max="5"
          ></multiselect>
          <i class="fal fa-angle-down multiselect-arrow"></i>
          <label for="career-academic-interest" class="multiselect-label">Career Academic Interests</label>
        </div>
        <div style="position: relative">
          <textarea
            id="description"
            v-model="editFileObj.description"
            type="text"
            rows="4"
            class="modal-textarea"
            maxlength="150"
          />
          <label for="description" class="multiselect-label">Description</label>
        </div>
        <div class="modal-checkbox-container">
          <label for="feature-resource-file-modal" style="width: unset" class="eu-checkbox-container">
            Featured resource
            <input
              v-if="isPartnerTab"
              id="feature-resource-file-modal"
              type="checkbox"
              v-model="editFileObj.is_partner_featured"
            />
            <input else id="feature-resource-file-modal" type="checkbox" v-model="editFileObj.featured" />
            <span class="eu-checkmark"></span>
            <span class="eu-checkmark-tooltip">
              <span class="link">
                <i class="fad fa-question-circle"></i>
                <div class="eu-resource-desc" style="right: unset; top: -33px">
                  <span class="eu-resource-desc-left-arrow"></span>
                  <span class="eu-resource-desc-el">
                    <p class="eu-resource-desc-text">
                      Check this box if you want this file to be highlighted in your students’ resource library.
                    </p>
                  </span>
                </div>
              </span>
            </span>
          </label>
<!--          <label for="share-in-campus-checkbox" v-if="showShareBtn" style="width: unset" class="eu-checkbox-container">-->

<!--            Share in {{ isPartnerTenant ? "Feed" : "Campus360" }}-->
<!--            <input id="share-in-campus-checkbox" type="checkbox" v-model="sharePostOnFeed" />-->
<!--            <span class="eu-checkmark"></span>-->
<!--            <span class="eu-checkmark-tooltip">-->
<!--              <span class="link">-->
<!--                <i class="fad fa-question-circle"></i>-->
<!--                <div class="eu-resource-desc" style="right: unset; top: -33px">-->
<!--                  <span class="eu-resource-desc-left-arrow"></span>-->
<!--                  <span class="eu-resource-desc-el">-->
<!--                    <p class="eu-resource-desc-text">-->
<!--                      {{-->
<!--                        isPartnerTenant-->
<!--                          ? "Check this if you want this to be shared on your Partner Profile."-->
<!--                          : "Check this box if you want this file to be highlighted in the Community Library."-->
<!--                      }}-->
<!--                    </p>-->
<!--                  </span>-->
<!--                </div>-->
<!--              </span>-->
<!--            </span>-->
<!--          </label>-->
        </div>
        <div class="dialog-error-container">
          <p class="error">{{ error }}</p>
        </div>
      </form>
      <div class="dialog-actions-container">
        <button @click="saveEditFile" v-if="editFile" class="iu-main-btn small main solid" aria-label="Save file.">
          Save
        </button>
        <button
          @click.prevent="submitFiles"
          v-if="!editFile"
          class="iu-main-btn small solid main"
          aria-label="Submit uploading files."
        >
          Save
        </button>
        <button
          @click.prevent="closeFileModal"
          v-if="!editFile"
          class="iu-main-btn small gray outlined"
          aria-label="Cancel editing file."
        >
          Cancel
        </button>
        <button
          @click.prevent="closeEditMode"
          v-if="editFile"
          class="iu-main-btn small gray outlined"
          aria-label="Close upload file modal."
        >
          Back
        </button>
      </div>
    </div>
  </modal>
</template>

<script lang="ts">
import Modal from "../../../components/ModalExtended.vue";
import Multiselect from "vue-multiselect";
import { validateExtensionFile } from "../../../utils/TypecheckUtils";
import ResourceLibraryService from "../../services/ResourceLibraryService";
import FeedService from "../../../feed/services/FeedService";
import Cookies from "js-cookie";
import { resourceLabelOptions } from "../../../utils/constants";
import SmallDropzoneComponent from "../../../form/components/SmallDropzoneComponent.vue";
import InputBase from "../../../components/formBase/InputBase.vue";
import SelectBase from "../../../components/formBase/SelectBase.vue";
import LoadingSpinner from "../../../components/LoadingSpinner.vue";
import { files } from "jszip";
import { useCommonDataStore } from '../../../stores/commonData';

export default {
  name: "upload-file-modal",
  components: { Modal, Multiselect, SmallDropzoneComponent, InputBase, SelectBase, LoadingSpinner },

  props: [
    "showShareBtn",
    "isModalActive",
    "currentDepartment",
    "filterData",
    "list_departments",
    "department_name",
    "currentGroup",
    "currentPartner",
    "isPartnerTenant",
    "isPartnerTab",
  ],

  computed: {
    deptSingular() {
      return useCommonDataStore().getFeatureName("department_singular");
    },
  },

  data() {
    return {
      error: "",
      files: [],
      editFileObj: {
        name: "",
        description: "",
        department: null,
        focus_areas: "",
        countries: "",
        identities: "",
        career_academic_interests: "",
        label: resourceLabelOptions[0],
        group: "",
        featured: false,
        is_partner_featured: false,
        thumbnail_url: "",
        resource_type: "",
        thumbnail: {},
      },
      thumbnail_changed: false,
      thumbnail_deleted: false,
      editFileTemp: null,
      dragging: false,
      editFile: null,
      sharePostOnFeed: true,
      resourceLabelOptions,
      isLoading: false,
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        Accept: "application/json",
      },
    };
  },

  created() {
    this.service = new ResourceLibraryService();
    this.feedService = new FeedService();
    if (!this.isAdminRole) this.editFileObj.department = this.department_name ? this.department_name : null ;
  },

  mounted() {},

  methods: {
    closeFileModal() {
      this.$emit("close-file-modal");
      this.files = [];
      this.clearForm();
      this.editFile = null;
      this.editFileTemp = null;
    },

    closeEditMode() {
      this.error = "";
      this.editFileObj.name = this.editFileTemp.name;
      this.editFileObj.description = this.editFileTemp.description;
      this.editFileObj.focus_areas = this.editFileTemp.focus_areas;
      this.editFileObj.countries = this.editFileTemp.countries;
      this.editFileObj.identities = this.editFileTemp.identities;
      this.editFileObj.career_academic_interests = this.editFileTemp.career_academic_interests;
      this.editFileObj.department = this.editFileTemp.department;
      this.editFileObj.thumbnail_url = this.editFileTemp.thumbnail_url;
      if (this.isPartnerTab) {
        this.editFileObj.is_partner_featured = this.editFileTemp.featured;
      } else {
        this.editFileObj.featured = this.editFileTemp.featured;
      }
      this.editFileObj.label = this.editFileTemp.label;
      this.editFile = null;
      this.thumbnail_changed = false;
      this.thumbnail_deleted = false;
    },

    editFileClicked(filename) {
      this.editFileObj = this.files.find((e) => e.name === filename);
      this.editFileTemp = { ...this.editFileObj };
      this.editFile = filename;
      this.error = "";
      if (!this.isAdminRole) this.editFileObj.department = this.department_name;
    },

    removeFile(index) {
      this.files.splice(index, 1);
    },

    handleUploadThumbnail(fileObject) {
      this.thumbnail_changed = true;
      this.thumbnail_deleted = false;
      this.editFileObj.thumbnail = fileObject;
      this.editFileObj.thumbnail_url = fileObject.fileUrl;
    },

    handleRemoveThumbnail() {
      this.thumbnail_deleted = true;
      this.editFileObj.thumbnail = {};
    },

    handleFileUpload(event) {
      const uploadFiles = event.dataTransfer ? event.dataTransfer.files : event.target.files;
      const imageExt = ["jpg", "jpeg", "png", "gif", "webp"];
      const docExt = ["txt", "pdf", "doc", "docx", "xlsx", "ppt", "pptx"];
      if (!uploadFiles.length) return;
      for (let i = 0; i < uploadFiles.length; i++) {
        this.error = "";
        let file = {
          file_url: URL.createObjectURL(uploadFiles[i]),
          file: uploadFiles[i],
          name: uploadFiles[i].name,
          file_type: null,
          label: resourceLabelOptions[0],
          department: "",
          status: "pending",
          error: null,
        };
        if (validateExtensionFile(uploadFiles[i].name, imageExt)) {
          file.file_type = "IMG";
        } else if (validateExtensionFile(uploadFiles[i].name, docExt)) {
          file.file_type = "DOC";
        } else {
          this.error = "Please use a supported image filetype.";
          return;
        }

        if (!this.files.some((e) => e.name === file.name)) {
          this.files.push(file);
        } else {
          this.error = `File with name '${file.name}' already exists.`;
          return;
        }
      }
    },

    async saveEditFile() {
      if (this.isLoading) return;
      this.isLoading = true;
      if (this.editFileObj.thumbnail && this.thumbnail_changed && !this.thumbnail_deleted) {
        const thumbnailRes = await this.feedService.uploadFile(this.editFileObj.thumbnail, this.headers);
        if (thumbnailRes && thumbnailRes.success) {
          this.editFileObj.thumbnail_url = thumbnailRes.resource;
        } else {
          this.error = "Something went wrong, please contact our support.";
        }
      }

      this.thumbnail_changed = false;
      this.thumbnail_deleted = false;

      const temp = this.files.filter((e) => e.image_url !== this.editFileObj.image_url);
      if (temp.some((e) => e.name === this.editFileObj.name)) {
        this.editFileObj.name = this.editFile;
        this.error = "File with that name already exists.";
      } else {
        this.editFile = null;
        this.error = "";
      }
      this.isLoading = false;
    },

    async createFeedPostForFiles(filesList) {
      const filteredFiles = {};
      // add files to separate lists in order from which department they are - so we can create separate feed posts for them.
      if (this.currentGroup) {
        const post = { files: filesList["map"]((e) => e.id) };
        post["text"] = filesList["length"] > 1 ? "" : !!filesList[0].description ? filesList[0].description : "";
        post["group"] = this.currentGroup;
        await this.feedService.createGroupPost(post, this.headers);
        this.reloadResources();
      } else if (this.currentPartner) {
        const post = { files: filesList["map"]((e) => e.id) };
        post["text"] = filesList["length"] > 1 ? "" : !!filesList[0].description ? filesList[0].description : "";
        post["partner"] = this.currentPartner;
        await this.feedService.createPartnerPost(post, this.headers);
        this.reloadResources();
      } else {
        filesList.forEach((f) => {
          if (!filteredFiles[f.department.name]) {
            filteredFiles[f.department.name] = [];
          }
          filteredFiles[f.department.name].push(f);
        });

        for (const [dept, files] of Object.entries(filteredFiles)) {
          const post = { files: files["map"]((e) => e.id) };

          post["text"] = files["length"] > 1 ? "" : !!files[0].description ? files[0].description : "";
          post["department"] = dept;
          if (post["text"] === undefined || post["text"] === "undefined") {
            post["text"] = "";
          }
          await this.feedService.createPost(post, this.headers);
        }
        this.reloadResources();
      }
    },

    async submitFiles(): Promise<void> {
      if (this.isLoading) return;
      this.isLoading = true;
      this.thumbnail_changed = false;
      this.thumbnail_deleted = false;
      let failure = false;
      const uploadedFiles = [];
      if (this.files.length == 0) {
        this.isLoading = false;
        this.closeFileModal();
      }
      this.files.forEach(async (file) => {
        if (file.name.length > 0) {
          file.status = "uploading";
          if (this.currentDepartment) {
            file.department = this.currentDepartment;
          }
          if (this.currentGroup) {
            file.group = this.currentGroup;
          }
          if (this.currentPartner) {
            file.partner = this.currentPartner;
          }
          const res = await this.service.postFormData(file, this.headers);
          if (res?.success) {
            uploadedFiles.push(res.resource);
            file.status = "uploaded";
            this.files = this.files.filter((e) => e.name !== file.name);
            if (this.files.length == 0) {
              if (this.sharePostOnFeed) {
                this.createFeedPostForFiles(uploadedFiles);
              }
              this.isLoading = false;
              this.clearForm();
              this.closeFileModal();
              this.reloadResources();
            }
          } else {
            file.status = "failed";
            failure = true;
            this.error = `Upload failed for file ${file.name}: Server error.`;
            this.isLoading = false;
          }
        } else {
          file.status = "failed";
          this.error = `Upload failed for file ${file.name}: Required file details missing.`;
          failure = true;
          this.isLoading = false;
        }
      });
    },
    clearForm() {
      this.editFileObj = {
        name: "",
        description: "",
        department: null,
        focus_areas: "",
        countries: "",
        identities: "",
        career_academic_interests: "",
        resource_type: "",
        label: resourceLabelOptions[0],
        featured: false,
        is_partner_featured: false,
        thumbnail: {},
        thumbnail_url: "",
      };
      this.thumbnail_changed = false;
      this.thumbnail_deleted = false;
    },

    reloadResources() {
      this.$emit("reloadResources");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-container {
  width: 600px;
}

.title-container {
  width: 100%;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  color: #444444;
  line-height: 1.2;
  position: relative;
}

.close-button {
  color: #95919b;
  font-size: 24px;
  border: none;
  background-color: transparent;
  position: absolute;
  right: 0;
  top: 0;

  &:hover {
    cursor: pointer;
    color: #88858e;
  }
}

.resource-form {
  width: 100%;
  margin-top: 24px;
  display: grid;
  gap: 16px;
}

.multiselect-arrow {
  position: absolute;
  top: 14px;
  z-index: 2;
  right: 12px;
  color: #685df3;
  font-size: 15px;
  font-weight: 600;
  pointer-events: none;

  &:hover {
    cursor: pointer;
    color: #9f87e0;
  }
}

.multiselect-label {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.15;
  color: #3b3355;
  background-color: #fff;
  position: absolute;
  left: 12px;
  padding: 0 6px 2px;
  top: -8px;
  width: unset !important;
}

.dialog-multiselect-wrapper {
  position: relative;
  cursor: pointer;
}

.modal-textarea {
  padding: 12px 0 0 12px;
  font-size: 16px;
  border-radius: 6px;
  border: 1px solid #949494;
  resize: none;
  background-color: #fff;

  &:active {
    border-color: #685df3;
  }

  &:focus {
    border-color: #685df3;
  }
}

.two-inputs-row {
  display: flex;
  width: 100%;
  gap: 8px;
  overflow: visible;
}

.dialog-error-container {
  height: 12px;
  color: #f16b4c;
  font-size: 12px;
}

.dialog-actions-container {
  display: flex;
  justify-content: center;
  gap: 12px;
}

.modal-checkbox-container {
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
  justify-content: center;
}

@media screen and (max-width: 632px) {
  .modal-checkbox-container {
    gap: 16px;
  }
  .modal-container {
    width: 100%;
    margin: 0 16px;
  }

  .two-inputs-row {
    flex-direction: column;
  }
}
</style>

<style lang="scss">
.dialog-multiselect-wrapper {
  .multiselect__tags {
    border-width: 1px;
    border-radius: 6px;
  }
}
</style>
