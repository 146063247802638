<template>
  <section v-if="isLoading">
    <loading-spinner wrapperClass="wrapper" />
  </section>
  <section v-else>
    <section class="opportunity-page-header-container">
      <section class="image-container">
        <img :src="event.thumbnail" class="image" alt="Partner cover image" />
        <div class="tint"></div>
        <div class="back-button-container">
          <button @click="redirectTo('/opportunities')" class="back-button">
            <i class="fa-solid fa-chevron-left back-arrow"></i>
            <span class="text">Browse all opportunities</span>
          </button>
        </div>
        <div class="text-container">
          <span class="title">{{ event.title }}</span>
        </div>
      </section>

      <div class="event-header-btns">
        <a
          v-if="event.apply_url"
          :href="event.apply_url"
          @click="increaseClickThrough()"
          @keyup.enter="increaseClickThrough()"
          target="_blank"
          class="btn btn-primary"
        >
          Apply Now
        </a>
        <button
          v-else
          class="btn btn-primary"
          @click="toggleLead()"
          role="button"
          aria-label="Update your RSVP status for this opportunity"
          tabindex="0"
        >
          <span>{{ leadButtonLabel }}</span>
        </button>

        <a v-if="event.external_link && event.type !== 'Internships & Jobs'" :href="event.external_link" target="_blank" class="btn btn-secondary">
          Visit Website
        </a>

        <span class="separator"></span>

        <button
          class="btn"
          :class="event.is_bookmarked ? 'btn-outline-primary' : 'btn-outline'"
          @click="toggleBookmark()"
          role="button"
          :aria-label="
            event.is_bookmarked
              ? 'Remove this opportunity from your bookmarks'
              : 'Update your RSVP status for this opportunity'
          "
          tabindex="0"
        >
          <i v-if="event.is_bookmarked" class="fa-sharp fa-solid fa-bookmark"></i>
          <i v-else class="fa-sharp fa-regular fa-bookmark"></i>
          <span>{{ event.is_bookmarked ? 'Saved' : 'Save' }}</span>
        </button>

        <button
          v-if="event.can_manage"
          class="btn btn-primary"
          @click="redirectToEdit()"
          role="button"
          aria-label="Update your RSVP status for this opportunity (interested in/going)"
        >
          <span>Edit {{ capitalizeFirstLetterOnly(event.type_of_program_management) }}</span>
        </button>
      </div>
    </section>

    <div class="event-content-body-wrapper">
      <div class="event-content-left-bar">
        <div>
          <div v-if="!event.is_past_event" class="program-details-mobile">
            <div class="mobile-full-w content-card content-card-collapse">
              <div class="content-card-header" @click="toggleProgramDetailsCollapsed()">
                <span class="content-card-title">Program Details</span>
                <i class="fa-regular" :class="programDetailsCollapsed ? 'fa-angle-down' : 'fa-angle-up'"></i>
              </div>

              <div v-show="!programDetailsCollapsed" class="content-card-details">
                <program-details-card :event="event" />
              </div>
            </div>
          </div>

          <div v-if="event.type_of_program_management === 'program' && event.files.length !== 0">
            <div v-for="item in event.files" v-bind:key="item.id">
              <div v-if="item.resource_type === 'VID'" class="event-content-card">
                <!-- <h2 class="video-about-this">{{ item.name }}</h2> -->
                <div class="video-wrapper">
                  <iframe
                    :src="getYoutubeOrVimeoEmbedLink(item.resource_url)"
                    class="dept-video"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>

          <div class="event-content-card">
            <h2 class="about-title">About</h2>
            <p class="about-text" style="margin-bottom: 20px" v-html="formatTextToHtml(event.description)"></p>
            <br />
            <div v-if="event.benefits && event.type === 'Internships & Jobs'">
              <h2 class="about-title">Benefits</h2>
              <p class="about-text" style="margin-bottom: 20px" v-html="formatTextToHtml(event.benefits)"></p>
              <br />
            </div>
<!--            <a v-if="event.more_info_url" class="event-content-header-desc" :href="event.more_info_url" target="_blank">-->
<!--              More Information<i-->
<!--                style="margin-left: 6px; font-size: 14px"-->
<!--                class="fa-solid fa-arrow-up-right-from-square"-->
<!--              ></i-->
<!--            ></a>-->
            <p class="event-content-link-wrapper" style="padding: 16px 0; margin-top: 10px">
              <a
                :href="event.external_link"
                target="_blank"
                class="dabi-btn-primary"
                :class="!event.external_link ? 'disabled' : ''"
                aria-label="Visit the website for this opportunity"
              >
                Visit Website
                <i class="fa-regular fa-arrow-up-right-from-square nav-icon"></i>
              </a>
            </p>
          </div>

          <div v-if="event.files && event.files.some((e) => e.resource_type != 'VID')" class="event-content-card">
            <h2 class="event-about-this">Files</h2>
            <div v-for="item in event.files" v-bind:key="item.id">
              <div v-if="item.resource_type !== 'VID'">
                <div class="document-wrapper item-bg">
                  <div class="icon-section">
                    <a
                      v-if="item.resource_type !== 'DOC'"
                      :href="item.resource_url"
                      target="_blank"
                      aria-label="Show resource"
                    >
                      <!-- <i v-if="item.resource_type === 'VID'"  class="far fa-file-video" style="color:#9f2a8c" aria-hidden="true"></i> -->
                      <i
                        v-if="item.resource_type === 'URL'"
                        class="far fa-external-link"
                        style="color: #438ca4; font-size: 20px"
                        aria-hidden="true"
                      ></i>
                      <i
                        v-if="item.resource_type === 'IMG'"
                        class="far fa-file-image"
                        style="color: #f4c64d; font-size: 20px"
                        aria-hidden="true"
                      ></i>
                      <i
                        v-if="item.resource_type === 'URL_INTERNAL'"
                        class="far fa-external-link"
                        style="color: #438ca4; font-size: 20px"
                        aria-hidden="true"
                      ></i>
                      <i
                        v-if="item.resource_type === 'URL_EXTERNAL'"
                        class="far fa-external-link"
                        style="color: #438ca4; font-size: 20px"
                        aria-hidden="true"
                      ></i>
                    </a>
                    <i
                      v-if="item.resource_type === 'DOC'"
                      class="far fa-file-alt"
                      style="color: #8673fd"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <div class="text-section">
                    <a :href="item.resource_url" target="_blank" class="text-section-title"> {{ item.name }} </a>
                    <!-- <p class="text-section-description"> Posted by Admissions </p> -->
                  </div>
                  <div
                    class="option-section"
                    :aria-label="`Download ${item.name}`"
                    role="button"
                    @click="downloadItem(item.resource_url, item.name)"
                    @keyup.enter="downloadItem(item.resource_url, item.name)"
                    v-if="
                      item.resource_type != 'VID' &&
                      item.resource_type != 'URL' &&
                      item.resource_type != 'URL_INTERNAL' &&
                      item.resource_type != 'URL_EXTERNAL'
                    "
                  >
                    <i class="far fa-download"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="event-content-right-bar">
        <div class="program-details-desktop event-content-right-bar-wrapper">
          <program-details-card :event="event" />
        </div>

        <div
          v-if="event.type_of_program_management === 'program' && event.contacts.length > 0"
          class="event-content-right-bar-wrapper"
        >
          <p class="eu-feed-dept-address-title" style="padding: 0">Contacts</p>
          <div v-for="item in event.contacts" v-bind:key="item.uuid" class="department-contacts-containers">
            <img alt="Profile picture of user" :src="item.profile_picture" class="avatar" />
            <div>
              <a
                aria-label="Go to full name profile page"
                class="eu-feed-dept-social-href"
                :href="'/profiles/' + item.url"
                >{{ item.name }}</a
              >
              <p class="helpful-link-description">{{ capitalizeFirstLetterOnly(item.role) }}</p>
            </div>
          </div>
        </div>
        <div class="event-content-right-bar-wrapper">
          <div class="content-wrapper">
            <h2 class="event-content-address-title">Opportunity Type</h2>
            <div class="flex-wrapper-content">
              <p class="content-item">
                {{ opportunityType }}
              </p>
            </div>
          </div>
          <div v-if="event.focus_areas.length !== 0" class="content-wrapper">
            <h2 v-if="event.type_of_program_management === 'program'" class="event-content-address-title">Location</h2>
            <h2 v-else class="event-content-address-title">Focus Area</h2>
            <div class="flex-wrapper-content">
              <p v-for="item in event.focus_areas" v-bind:key="item.id" class="content-item">
                {{ item.name }}
              </p>
            </div>
          </div>
          <div v-if="event.audiences && event.audiences.length !== 0" class="content-wrapper">
            <h2 class="event-content-address-title">Audience</h2>
            <div class="flex-wrapper-content">
              <p v-for="item in event.audiences" v-bind:key="item.id" class="content-item">
                {{ item.name }}
              </p>
            </div>
          </div>
          <div
            v-if="event.career_academic_interests && event.career_academic_interests.length !== 0"
            class="content-wrapper"
            style="border-bottom: 0; margin-bottom: 0"
          >
            <h2 class="event-content-address-title">Career/Academic interests</h2>
            <div class="flex-wrapper-content">
              <p v-for="item in event.career_academic_interests" v-bind:key="item.id" class="content-item">
                {{ item.name }}
              </p>
            </div>
          </div>
        </div>

        <div v-if="event.partner_info" class="partner-wrapper">
          <img alt="Partner image" :src="event.partner_info.logo_url" class="partner-avatar" />

          <div class="partner-text">
            <span class="partner-title">
              By <b>{{ event.partner_info.name }}</b>
            </span>
            <span class="partner-subtitle"> {{ event.partner_info.followers }} followers </span>
          </div>

          <button v-if="isFollowing" class="btn btn-primary" @click="goToPartnerProfile(event.partner_info.name)">
            Visit
          </button>
          <button v-else class="btn btn-primary" @click="followPartner(event.partner_info.name)">Follow</button>
        </div>
      </div>
    </div>

    <div class="under-content-section">
      <div v-if="recommendedOpportunities.length" class="section">
        <h2 class="section-title">Other opportunities you may like</h2>

        <div class="event-cards-container">
          <partner-event-card
            v-for="(item, index) in recommendedOpportunities"
            :key="index"
            :item="item"
            @toggle-bookmark="handleBookmarkEvent"
          ></partner-event-card>
        </div>
      </div>
    </div>

    <Modal :isActiveProp="isModalActive" @close="closeModal()" :classProp="'iu-modal-content-container'">
      <div class="iu-modal-content">
        <h4 class="iu-modal-title-text">Get In Touch with Us</h4>

        <div>
          <p>
            Tell us more about your interest. Any additional details you provide will assist us in making your
            experience more personalized.
          </p>
          <p style="margin-top: 8px">Nothing more to add, no problem, just click 'Submit' and we'll be in touch.</p>
        </div>

        <div class="iu-modal-form-container">
          <div class="iu-input-field-container">
            <label
              for="first-name"
              class="iu-input-field-label-container iu-input-field-label-text"
              style="bottom: 75px"
            >
              Additional Info
            </label>
            <textarea
              id="lead-note"
              name="lead-note"
              cols="40"
              rows="3"
              v-model="lead_note"
              class="iu-input-field"
              style="height: 80px; overflow-x: hidden; resize: none; padding-top: 10px"
            ></textarea>
          </div>
        </div>
        <div class="iu-modal-actions-container">
          <button
            class="iu-main-btn small solid main iu-modal-save-button"
            aria-label="Add new user"
            @click="submitLead"
          >
            Submit
          </button>
          <button class="iu-main-btn small outlined gray" aria-label="Close add user modal" @click="closeModal">
            Cancel
          </button>
        </div>
      </div>
    </Modal>

    <Notification @close="closeNotification" :isActiveProp="isNotificationActive" :notification="notification" />
  </section>
</template>

<script lang="ts">
import * as Cookies from 'js-cookie';
import '../../meetings/utils/DateParser';
import EventsIndexService from '../services/EventsIndexService';
import RSVPService from '../services/RSVPService';
import { getYoutubeOrVimeoEmbedLink, capitalizeFirstLetterOnly, formatTextToHtml } from '../../utils/CustomUtils';
import FeedService from '../../feed/services/FeedService';
import LoadingSpinner from '../../components/LoadingSpinner.vue';
import Modal from '../../components/ModalExtended.vue';
import ProgramDetailsCard from './cards/ProgramDetailsCard.vue';
import EventUtils from '../utils/EventUtils';
import Notification from '../../components/Notification.vue';
import PartnerEventCard from './cards/PartnerEventCard.vue';

export default {
  components: { LoadingSpinner, Modal, ProgramDetailsCard, Notification, PartnerEventCard },
  name: 'dabi-event-details',
  props: [],
  computed: {
    opportunityType() {
      if (this.event.type_of_program_management === 'event') {
        return 'Event';
      }

      if (this.event.type) {
        return this.capitalizeFirstLetterOnly(this.event.type);
      }

      return 'Program';
    },
    isAttending() {
      return this.event.rsvp_status;
    },
    isFollowing() {
      return this.event.partner_info && this.event.partner_info.is_following;
    },
    leadButtonLabel() {
      if (this.event.type_of_program_management === 'program') {
        return this.event.is_interested ? 'Contacted' : 'Contact Us';
      }

      return this.event.is_interested ? 'Attending' : 'Attend';
    },
  },

  data() {
    return {
      isLoading: true,
      isModalActive: false,
      lead_note: '',
      event: null,
      headers: {
        'X-CSRFToken': Cookies.get('csrftoken'),
        Accept: 'application/json',
      },
      programDetailsCollapsed: false,
      recommendedOpportunities: [],
      isNotificationActive: false,
      notification: { msg: '', isError: false, icon: null },
    };
  },

  async created() {
    this.service = new EventsIndexService();
    this.feedService = new FeedService();
    this.rsvpService = new RSVPService();
    this.eventsService = new EventsIndexService();
    this.eventUtils = new EventUtils(this.eventsService, this.headers);

    await this.getPreData(true);
    await this.getRecommendedOpportunities();
  },

  methods: {
    capitalizeFirstLetterOnly,
    getYoutubeOrVimeoEmbedLink,
    formatTextToHtml,
    async getRecommendedOpportunities() {
      const { type: eventType } = this.$route.meta;

      const params = {
        limit: 4,
      };

      try {
        let res = null;

        if (eventType === 'event') {
          res = await this.service.getRecommendedEvents(params);
        } else if (eventType === 'program') {
          res = await this.service.getRecommendedPrograms(params);
        }

        this.recommendedOpportunities = res.map((item) => item.data).filter((item) => item.id !== this.event.id);
      } catch (e) {}
    },
    async getPreData(shouldIncreaseViews = false) {
      this.isLoading = true;

      const { type: eventType } = this.$route.meta;
      const opportunitySlug = this.$route.path.slice(1);

      try {
        let res = null;

        if (eventType === 'event') {
          res = await this.service.getPredataEvent(opportunitySlug);
        } else if (eventType === 'program') {
          res = await this.service.getPredataProgram(opportunitySlug);
        }

        if (res) {
          this.event = res;

          if (shouldIncreaseViews) {
            this.service.increaseViews(this.event.id);
          }
        }
      } catch (e) {}

      this.isLoading = false;
    },
    increaseClickThrough() {
      this.service.increaseClickThroughs(this.event.id);
    },
    goToPartnerProfile(partnerName) {
      this.$router.push(`/partners/${partnerName}`);
    },
    async followPartner(partner) {
      const res = await this.feedService.followPartnerFeed({ isFollowing: true, partner }, this.headers);
      if (res?.success && this.event.partner_info) {
        this.event.partner_info.followers = res.followers;
        this.event.partner_info.is_following = true;
      }
    },
    closeModal() {
      this.isModalActive = false;
    },
    async toggleLead() {
      if (this.event.is_interested) {
        const res = await this.service.removeLead(this.event.id);
        if (res.success) {
          await this.getPreData();
        }
      } else {
        this.isModalActive = true;
      }
    },
    toggleBookmark() {
      this.event.is_bookmarked ? this.handleRemoveBookmark(this.event) : this.handleAddBookmark(this.event);
    },
    async submitLead() {
      this.closeModal();
      const res = await this.service.createLead(this.event.id, this.lead_note);
      if (res.success) {
        this.lead_note = '';
        await this.getPreData();
      }
    },

    async handleAddBookmark(event): Promise<void> {
      const payload = {
        target: event.id,
        action: 'add',
      };

      await this.service.postBookmark(payload, this.headers);

      event.is_bookmarked = true;
    },

    async handleRemoveBookmark(event): Promise<void> {
      const payload = {
        target: event.id,
        action: 'remove',
      };

      await this.service.postBookmark(payload, this.headers);

      event.is_bookmarked = false;
    },

    async changeAttendance(isAttending: boolean): Promise<void> {
      if (this.isAttending === isAttending) {
        return;
      }

      const payload = {
        target: this.event.id,
        isAttending: isAttending,
      };

      const res = await this.rsvpService.postAttendance(payload, this.headers);

      if (res['success']) {
        this.isAttending = isAttending;
      }
    },
    closeRSVPBurger() {
      const burgerEl = document.getElementById('rsvp-burger');
      burgerEl.classList.remove('eu-resource-burger-active');
    },
    redirectTo(url) {
      this.$router.push(url);
    },
    redirectToEdit() {
      if (this.event.type_of_program_management === 'event') {
        window.location.href = `/events/edit/${this.event.id}`;
      } else {
        window.location.href = `/programs/edit/${this.event.id}`;
      }
    },
    async downloadItem(dataurl: string, filename: string) {
      const res = await fetch(dataurl);
      const blob = await res.blob();
      this.startDownload(blob, filename);
    },

    startDownload(content, filename) {
      var a = document.createElement('a');
      a.download = filename ? filename : 'resources';
      document.body.appendChild(a);
      a.href = URL.createObjectURL(content);
      a.style.display = 'none';
      a.click();
      document.body.removeChild(a);
    },
    toggleProgramDetailsCollapsed() {
      this.programDetailsCollapsed = !this.programDetailsCollapsed;
    },
    handleBookmarkEvent(item) {
      this.handleBookmark(item.id, item.is_bookmarked ? 'remove' : 'add');
      item.is_bookmarked = !item.is_bookmarked;
    },
    async handleBookmark(itemId, action: string): Promise<void> {
      const res = await this.eventUtils.handleBookmark(itemId, action);
      const notification = { msg: '', isError: false, icon: null };

      if (res.success) {
        notification.msg = `Bookmark successfully ${action == 'add' ? 'added' : 'removed'}`;
        notification.icon = 'fad fa-check-circle';
      } else {
        notification.msg = `Oops, something went wrong. Please reload page and try again.`;
        notification.isError = false;
        notification.icon = 'fad fa-exclamation-circle';
      }

      this.showNotification(notification);
    },
    closeNotification() {
      this.notification = { msg: '', isError: false, icon: null };
      this.isNotificationActive = false;
    },
    showNotification(notification) {
      this.notification = { ...notification };
      this.isNotificationActive = true;
    },
  },
};
</script>
<style scoped lang="scss">
.content-card {
  box-shadow: 0 0 10px 4px rgba(20, 20, 20, 0.04);
  background-color: #fff;
  border-radius: 24px;

  &-header,
  &-details {
    padding: 1.5rem 1.25rem;
  }

  &-title {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.2;
    color: #000;
  }

  &-collapse {
    .content-card-header {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 1rem;

      .content-card-title {
        flex: 1;
      }

      i {
        font-size: 1.25rem;
        font-weight: 600;
        line-height: 1.2;
        color: #05c1a2;
      }
    }

    .content-card-details {
      padding-top: 0;
    }
  }
}

.event-header-btns {
  max-width: 1400px;
  padding: 1rem 0;
  width: 100%;
  display: flex;
  gap: 0.625rem;

  @media screen and (max-width: 740px) {
    padding: 1rem 0.5rem;
  }

  @media screen and (max-width: 991px) {
    flex-direction: column;
  }

  .separator {
    margin-left: auto;

    @media screen and (max-width: 991px) {
      display: none;
    }
  }
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 2.125rem;
  border-radius: 100px;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1;
  color: #101828;
  cursor: pointer;
  outline: none;
  border: none;
  background-color: transparent;
  transition: none;

  &-primary {
    background-color: #2d98d2;

    &:hover {
      background-color: #0084cf;
    }
  }

  &-secondary {
    background-color: #05c1a2;

    &:hover {
      background-color: #008e82;
    }
  }

  &-outline {
    border: 2px solid #838ea1;

    &:hover {
      background-color: #f2f5f7;
    }

    i {
      color: #26354a;
    }
  }

  &-outline-primary {
    border: 2px solid #2d98d2;

    &:hover {
      background-color: #f1fafe;
    }

    i {
      color: #2d98d2;
    }
  }

  &-outline-secondary {
    border: 2px solid #05c1a2;

    &:hover {
      background-color: #e3faf2;
    }

    i {
      color: #05c1a2;
    }
  }
}

.program-details-mobile {
  display: none;

  @media screen and (max-width: 576px) {
    display: block;
  }

  .content-card-header,
  .content-card-details {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.program-details-desktop {
  display: block;

  @media screen and (max-width: 576px) {
    display: none;
  }
}

.avatar {
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 50%;
}

.item-bg {
  border: none;
  background: #f2f5f7;
}

.content-item {
  border-radius: 16px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  padding: 4px 8px;
  color: #516075;
  background: #f2f5f7;
}

.about-text {
  flex-grow: 0;
  font-size: 1.25rem;
  line-height: 1.35;
  color: #494949;
}

.about-title {
  font-size: 26px;
  font-weight: 500;
  color: #444444;
  margin: 16px 0;
  line-height: 1.31;
}

.helpful-link-description {
  color: #838ea1;
  font-size: 12px;
  font-weight: 600;
}

.partner-wrapper {
  background: #f2f5f7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 1.5rem;
  border-radius: 24px;

  .partner-avatar {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 100px;
  }

  .partner-text {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  .partner-title {
    color: #333;
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1;
  }

  .partner-subtitle {
    font-size: 0.813rem;
    font-weight: 600;
    line-height: 1;
    color: #6d798c;
  }
}

.nav-icon {
  color: #a9decf;
  font-size: 12px;
  margin-top: 4px;
}

.event-content-card {
  width: 100%;
  display: inline-block;
  padding: 16px 32px 16px 0;
  border-radius: 16px;
  margin-right: 0;
  margin-bottom: 24px;
  background: transparent;
  box-shadow: none;
}

.image-container {
  width: 100%;
  min-height: 22.5rem;
  max-width: 1400px;
  overflow: hidden;
  border-radius: 0 0 24px 24px;
  position: relative;
  padding: 2.5rem 2rem;
  display: flex;
  flex-direction: column;

  .image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  .tint {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .back-button-container {
    width: 100%;
    position: relative;

    .back-button {
      padding: 0.75rem 1.5rem;
      color: #101828;
      background-color: #d8dce5;
      border: none;
      border-radius: 100px;
      font-size: 1rem;
      font-weight: 600;

      &:hover {
        cursor: pointer;
        background-color: #838ea1;
      }

      .back-arrow {
        color: #2d98d2;
        font-size: 0.875rem;
        font-weight: 500;
      }
    }
  }
}
.text-container {
  position: relative;
  margin-top: auto;

  .title {
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 1.2;
    color: #fff;
  }
}

.opportunity-page-header-container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 2px solid #c3cbd6;
  padding: 0 2rem;
}

.under-content-section {
  padding: 0 2rem;
  max-width: 1472px;
  margin: 0 auto;

  .section {
    margin-bottom: 3rem;
  }
}

.section .section-title {
  margin-bottom: 1.5rem;
}

.section-title {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.2;
  color: #333;

  @media screen and (max-width: 576px) {
    font-size: 1.25rem;
  }
}

.event-cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  gap: 1rem;
}

@media screen and (max-width: 740px) {
  .opportunity-page-header-container {
    padding: 0;

    .image-container {
      padding: 1.5rem 1rem;

      .back-button-container {
        .back-button {
          height: 3rem;
          width: 3rem;
          display: flex;
          align-items: center;
          justify-content: center;

          .text {
            display: none;
          }

          .back-arrow {
            font-size: 1rem;
            color: #101828;
          }
        }
      }

      .text-container {
        .title {
          font-size: 2rem;
        }
      }
    }
  }

  .partner-page-nav-section {
    padding: 0.75rem 1.25rem 0;
  }
}
</style>
