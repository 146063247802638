<template>
  <section v-if="isPageLoading">
    <loading-spinner wrapperClass="wrapper" />
  </section>
  <section v-else>
    <div class="flex-column-center">
      <div class="main-wrapper">
        <div class="accounts-title-wrapper">
          <common-title title="Manage accounts" description="Manage and assign different roles to your users." />
        </div>
        <div id="main" class="eu-simple-navbar">
          <router-link
            v-if="isAdmin || !isPartnerTenant()"
            to="/accounts"
            aria-label="Go to Accounts manage page"
            class="eu-simple-navbar-item active"
          >
            Accounts
          </router-link>
          <router-link
            v-if="isAdmin && isPartnerTenant()"
            to="/accounts/partners"
            aria-label="Go to Partners manage page"
            class="eu-simple-navbar-item"
          >
            Partners
          </router-link>
          <router-link
            v-if="isManagerOnly && isPartnerTenant()"
            to="/accounts/partners"
            aria-label="Go to manage Managers page"
            class="eu-simple-navbar-item"
          >
            Managers
          </router-link>
        </div>
        <div class="eu-search">
          <form action="" class="eu-search-form" @submit.prevent="search()" style="margin-top: 50px" role="search">
            <input v-model="searchInput" type="search" placeholder="Search for a user" class="eu-search-input" />
            <button class="eu-search-btn-icon" aria-label="search">
              <i class="eu-search-icon fas fa-search"></i>
            </button>
          </form>
        </div>
        <div class="filter-container">
          <multiselect-filter-component
            placeholder="Any Role"
            filter_name="Roles"
            :options="roles"
            @filter-data="filterData"
            additionalStyle="width: 100%"
          />
          <multiselect-filter-component
            v-if="!isPartnerTenant() && areDeptsEnabled"
            :placeholder="`Any ${deptSingular}`"
            filter_name="Departments"
            :options="departments"
            @filter-data="filterData"
            additionalStyle="width: 100%"
          />
          <multiselect-filter-component
            :placeholder="`Any Audience`"
            filter_name="Audience"
            :options="audiences"
            @filter-data="filterData"
            additionalStyle="width: 100%"
          />
          <multiselect-filter-component
            placeholder="Any Status"
            filter_name="Status"
            :options="[
              { name: 'Active' },
              { name: 'Not active' },
              { name: 'Invitation sent' },
              { name: 'Invitation not sent' },
              { name: 'Disabled' },
            ]"
            @filter-data="filterData"
            additionalStyle="width: 100%;margin-right:0"
          />
        </div>
        <div class="button-container">
          <div style="flex-direction: row">
            <button
              id="bulk_import_manage_account"
              class="iu-btn-solid"
              style="margin-right: 4px; font-size: 14px"
              @click="openBulkModal"
              aria-haspopup="dialog"
              :disabled="userCount >= usersLimit"
            >
              Bulk Import
            </button>

            <button
              id="manual_add_manage_account"
              class="iu-btn-solid"
              style="margin-right: 8px; font-size: 14px"
              :disabled="userCount >= usersLimit"
              @click="openAddUserModal"
            >
              Manual Add
            </button>
          </div>

          <div style="position: relative">
            <button
              ref="exportButton"
              class="iu-btn-light export-btn"
              style=""
              aria-haspopup="true"
              @click="openExportMenu"
            >
              Export as
            </button>
            <i class="fal fa-angle-down eu-select-arrow" style="left: -35px; color: #00a1b3" aria-hidden="true"></i>

            <div
              id="export-menu"
              ref="exportMenu"
              v-closable="{ exclude: ['exportMenu', 'exportButton'], handler: 'closeExportMenu' }"
              class="eu-resource-burger"
              style="width: 107px; right: 40px; margin-top: 7px"
            >
              <span
                tabindex="0"
                role="button"
                aria-label="Download csv file"
                class="eu-resource-burger-el"
                @click="handleExport('csv')"
                @keyup.enter="handleExport('csv')"
              >
                <span>CSV File</span>
              </span>

              <span
                tabindex="0"
                role="button"
                aria-label="Download xlsx file"
                class="eu-resource-burger-el"
                @click="handleExport('xlsx')"
                @keyup.enter="handleExport('xlsx')"
              >
                <span>XLSX File</span>
              </span>
            </div>
          </div>
          <div class="total-accounts">
            Total Accounts: <b>{{ userCount }}/{{ usersLimit }}</b>
          </div>
        </div>

        <div v-if="isLoading">
          <loading-spinner wrapperClass="wrapper" />
        </div>

        <div v-else-if="students.length > 0">
          <div class="eu-selected-toolbar" v-if="selected.length > 1">
            <span class="eu-selected-toolbar-text"> {{ selected.length }} users selected. </span>
            <span @click="openMultiDeleteModal()" style="cursor: pointer">
              <i class="fa-solid fa-trash-alt eu-selected-toolbar-icon"></i>Delete
            </span>

            <span @click="suspendMultipleStudents('suspend')" style="cursor: pointer">
              <i class="fas fa-toggle-on eu-selected-toolbar-icon"></i>Suspend
            </span>
            <span @click="suspendMultipleStudents('restore')" style="cursor: pointer">
              <i class="fas fa-toggle-off eu-selected-toolbar-icon"></i>Restore
            </span>
            <span @click="sendEmail()" style="cursor: pointer">
              <i class="fas fa-envelope eu-selected-toolbar-icon"></i>Send Emails
            </span>
            <span @click="cancelSelection()" style="cursor: pointer">
              <i class="fas fa-times eu-selected-toolbar-icon"></i>Cancel
            </span>
          </div>
          <div class="table-container" style="width: 100%; overflow-x: auto; margin: 30px 0; overflow-y: visible">
            <students-table
              :students="students"
              :sortBy="sortBy"
              :sortDirection="sortDirection"
              :selected="selected"
              :selectedAll="selectedAll"
              :areDeptsEnabled="areDeptsEnabled"
              @sort="sort"
              @toggle-student="toggleStudent"
              @toggle-all="toggleAll"
              @edit-student="editStudent"
              @send-email="sendEmail"
              @delete-student="openDeleteModal"
              @view-profile="handleViewProfileEvent"
            />
          </div>
        </div>
        <div v-else class="no-users-container" style="margin-top: 30px">
          <i class="fad fa-user-circle large-icon"></i>
          <p v-if="isSearching">No results.</p>
          <p v-else>
            There are no users in the system. Bulk import<br />
            multiple students by uploading a spreadsheet or<br />
            manually add one student at a time.
          </p>
        </div>

        <bulk-upload-modal
          v-if="isBulkModalActive"
          :sso_provider="sso_provider"
          :roles="roles"
          @started-upload="changeUploadStatus"
          @close-modal="closeBulkModal"
        />

        <edit-user-modal
          ref="editUserModal"
          v-if="isEditModalActive"
          :preselectedAction="preselectedAction"
          :selectedUser="studentForEdit"
          :departmentList="departments"
          :sso_provider="sso_provider"
          :roleList="roles"
          @closeEditModal="closeEditModal"
          @resetPassword="resetPasswordHandler"
          @resetUser="resetUserHandler"
          @deleteUser="deleteUserHandler"
          @editUser="editUserHandler"
          @deleteMultipleUsers="deleteMultipleUsers"
        ></edit-user-modal>
        <add-user-modal
          ref="addUserModal"
          v-show="isAddModalActive"
          :sso_provider="sso_provider"
          :roleList="roles"
          @closeAddUserModal="closeAddUserModal"
          @addUser="addUserHandler"
        ></add-user-modal>

        <Modal :isActiveProp="isMultiDeleteModalActive" @close="closeMultiDeleteModal">
          <div class="row" style="width: auto">
            <div style="display: flex; flex-direction: row">
              <i class="fad fa-user-circle small-icon"></i>
            </div>
            <p tabindex="0" role="heading" class="delete-text">
              Are you sure you want to delete these users?<br />This action cannot be undone.
            </p>
            <p class="delete-text">For security reasons, please type in your password:</p>
            <form @submit.prevent="deleteMultipleUsers()" class="field-wrapper hasValue" style="margin-bottom: 20px">
              <div class="da-form-formItem permission-input">
                <input
                  id="pass"
                  type="password"
                  autocomplete="off"
                  maxlength="255"
                  class="da-components-forms-input large js-da-login-email"
                  v-model="password"
                />
              </div>
              <div class="field-placeholder" style="bottom: 22px">
                <span> <label for="pass">Password </label><span class="required-star">*</span></span>
              </div>
            </form>
          </div>

          <div style="margin-bottom: 30px">
            <button
              class="da-components-button da-btn-solid eu-file-upload-modal-btn-primary orange"
              @click="deleteMultipleUsers()"
            >
              Delete User
            </button>

            <button
              class="da-components-button da-btn-standard eu-file-upload-modal-btn-secondary"
              @click="closeMultiDeleteModal"
            >
              Cancel
            </button>
          </div>
        </Modal>
        <div class="accounts-view-section">
          <profile-card-modal
            :isModalActive="isProfileModalActive"
            :user="userForDisplay"
            @onClose="closeUserProfileModal"
          />
        </div>
        <Notification @close="closeNotification" :isActiveProp="isNotificationActive" :notification="notification" />
      </div>
    </div>
  </section>
</template>
<script lang="ts">
import Cookies from 'js-cookie';
import handleOutsideClick from '../../directives/handleOutsideClick';
import BulkUploadService from '../services/BulkUploadService';
import ExportService from '../services/ExportService';
import StudentService from '../services/StudentService';
import StudentsTable from './StudentsTable.vue';
import AccountsService from '../../accounts/services/AccountsService';
import ManualUploadModal from './modals/ManualUploadModal.vue';
import BulkUploadModal from './modals/BulkUploadModal.vue';
import Modal from '../../components/ModalExtended.vue';
import Notification from '../../components/Notification.vue';
import LoadingSpinner from '../../components/LoadingSpinner.vue';
import { focusElement } from '../../utils/CustomUtils';
import { manualImportUser } from './utils/UsersUtils';
import MultiselectFilterComponent from '../../form/components/MultiselectFilterComponent.vue';
import ProfileCardModal from './ProfileCardModal.vue';
import RoleService from '../../roles/services/RoleService';
import RolePermissions from '../../roles/components/RolePermissions.vue';
import EditUserModal from './modals/EditUserModal.vue';
import AddUserModal from './modals/AddUserModal.vue';
import { isPartnerTenant } from '../../utils/CustomUtils';
import CommonTitle from '../../components/CommonTitle.vue';
import { LICENSE_ONLY_FEATURES, isFeatureEnabled } from '../../utils/PermissionUtils';
import { useCommonDataStore } from '../../stores/commonData';

export default {
  name: 'student-bulk-interface',

  directives: { closable: handleOutsideClick },

  props: ['isAdmin'],

  components: {
    Modal,
    ManualUploadModal,
    BulkUploadModal,
    StudentsTable,
    Notification,
    LoadingSpinner,
    MultiselectFilterComponent,
    ProfileCardModal,
    RolePermissions,
    EditUserModal,
    AddUserModal,
    CommonTitle,
  },

  computed: {
    deptSingular() {
      return useCommonDataStore().getFeatureName('department_singular');
    },
  },

  data() {
    return {
      searchInput: '',
      fullAccountDeactivation: false,
      isSearching: false,
      isLoading: true,
      isPageLoading: true,
      hasImported: false,

      students: [],
      selected: [],
      selectedAll: false,

      sortBy: 'first_name',
      sortDirection: 'up',

      student: null,
      studentForEdit: null,
      isEditModalActive: false,
      studentForDelete: null,
      userToReset: null,
      selectedRole: null,
      preselectedRole: undefined,
      preselectedAction: '',

      isAddModalActive: false,
      isBulkModalActive: false,
      isManualModalActive: false,
      isDeleteModalActive: false,
      isResetUserModalActive: false,
      isEditRoleModalActive: false,
      isMultiDeleteModalActive: false,

      roleData: null,
      availableRoles: null,

      selectedDepartments: [],
      selectedRoles: [],
      selectedStatuses: [],
      selectedAudiences: [],

      password: '',

      error: '',

      isNotificationActive: false,
      notification: { msg: '', isError: false, icon: null },

      isProfileModalActive: false,
      userForDisplay: null,
      areDeptsEnabled: false,

      headers: {
        'X-CSRFToken': Cookies.get('csrftoken'),
        Accept: 'application/json',
      },
    };
  },

  async created() {
    this.areDeptsEnabled = isFeatureEnabled(LICENSE_ONLY_FEATURES.DEPTS);
    this.bulkService = new BulkUploadService();
    this.studentService = new StudentService();
    this.exportService = new ExportService();
    this.accountsService = new AccountsService();
    this.roleService = new RoleService();
    await this.getPreData();
    this.fetchUsers();
  },

  methods: {
    isPartnerTenant,
    async getPreData() {
      this.isPageLoading = true;
      const res = await this.accountsService.getPreData();
      if (res.success) {
        this.sso_provider = res.sso_provider;
        this.usersLimit = res.users_limit;
        this.userCount = parseInt(res.user_count);
        this.roles = res.roles;
        this.departments = res.departments;
        this.audiences = res.audiences;
        this.isManagerOnly = res.is_manager_only;
      }
      this.isPageLoading = false;
    },
    closeUserProfileModal() {
      this.isProfileModalActive = false;
    },
    userToUserPreviewMapper(user) {
      let focusAreasWithName = [];
      user.focus_areas.forEach((fa) => {
        focusAreasWithName.push({ name: fa });
      });

      let hours = user.hours;

      if (user.hours) {
        hours = hours.sort((a, b) => (a.startTime > b.startTime ? 1 : -1));
        hours = hours.slice(0, 5);
      }

      return {
        first_name: user.first_name,
        last_name: user.last_name,
        profile_pic: user.profile_pic,
        id: user.id,
        department: user.department,
        focus_areas: focusAreasWithName,
        role: {
          is_mentor: user.is_mentor,
        },
        profile_url: user.url,
        upcoming_hours: hours,
      };
    },

    handleViewProfileEvent(user) {
      this.userForDisplay = this.userToUserPreviewMapper(user);
      this.openViewProfileModal();
    },

    openViewProfileModal() {
      this.isProfileModalActive = true;
    },

    closeViewProfileModal() {
      this.isProfileMOdalActive = false;
    },

    async fetchUsers() {
      this.isLoading = true;
      this.selected = [];
      this.selectedAll = false;

      if (
        this.searchInput === '' &&
        this.selectedDepartments.length === 0 &&
        this.selectedRoles.length === 0 &&
        this.selectedAudiences.length === 0 &&
        this.selectedStatuses.length === 0
      ) {
        this.isSearching = false;
      }

      const result = await this.bulkService.getStudents({
        q: this.searchInput,
        sort_by: this.sortBy,
        departments: this.selectedDepartments,
        roles: this.selectedRoles,
        audiences: this.selectedAudiences,
        status: this.selectedStatuses,
        sort_direction: this.sortDirection,
      });

      this.students = result['students'];

      this.isLoading = false;
    },

    showNotification(notification) {
      this.isNotificationActive = true;
      this.notification = notification;
    },
    closeNotification() {
      this.isNotificationActive = false;
    },

    sort(column: string) {
      if (this.sortBy === column) {
        this.sortDirection = this.sortDirection === 'up' ? 'down' : 'up';
      } else {
        this.sortBy = column;
        this.sortDirection = 'up';
      }

      this.fetchUsers();
    },

    toggleStudent(id) {
      const index = this.selected.indexOf(id);

      if (index > -1) {
        this.selected.splice(index, 1);
      } else {
        this.selected.push(id);
      }
    },

    toggleAll() {
      this.selectedAll = !this.selectedAll;

      if (this.selectedAll) {
        this.selected = this.students.map((x) => x.id);
      } else {
        this.selected = [];
      }
    },

    cancelSelection() {
      this.selectedAll = false;
      this.selected = [];
    },

    search() {
      this.isSearching = true;

      this.fetchUsers();
    },

    async addStudent(params: {
      studentObject: {
        first_name: string;
        last_name: string;
        email: string;
        role: string;
        department: string;
      };
      sendInvite: boolean;
      isSSO: boolean;
      is_edit_user: boolean;
      disabled: boolean;
    }) {
      this.preselectedRole = undefined;
      const result = await manualImportUser(params, this.bulkService, this.headers);
      if (result.status === 'success') {
        this.userCount += 1;
        this.fetchUsers();
        this.showNotification({ msg: result.message, icon: 'fad fa-check-circle' });
      }
      if (result.status === 'error') {
        this.showNotification({ msg: result.message, isError: true, icon: 'fad fa-exclamation-circle' });
      }

      this.searchInput = '';
      this.$refs.addUserModal.closeAddUserModal(true);
      this.closeEditModal();
    },

    async sendEmail(id = undefined) {
      const data = {
        ids: id ? [id] : this.selected,
      };

      await this.bulkService.putFormData(data, this.headers);

      this.fetchUsers();
    },

    async resetPassword(email) {
      const res = await this.accountsService.sendResetPasswordEmail({ email }, this.headers);
      if (res.success) {
        this.showNotification({ msg: res.message, icon: 'fad fa-check-circle' });
      } else {
        this.showNotification({ msg: res.message, isError: true, icon: 'fad fa-exclamation-circle' });
      }
      focusElement('notification_title');
    },
    openEditUserModal(preselectedAction = '') {
      this.preselectedAction = preselectedAction;
      this.isEditModalActive = true;
    },

    editStudent(student) {
      this.studentForEdit = student;
      this.openEditUserModal();
    },
    editRole(student) {
      this.studentForEdit = student;
      this.preselectedRole = student.role.name;
      this.selectedRole = this.preselectedRole;
      focusElement('modal_edit_role');
      this.openEditRoleModal(student);
    },

    async deleteStudent(id) {
      const params = {
        id,
        password: this.password,
      };

      this.closeEditModal();
      const res = await this.studentService.deleteStudent(params, this.headers);
      if (res.success) {
        this.showNotification({ msg: res.message, icon: 'fad fa-check-circle' });
        this.userCount -= 1;
      } else {
        this.showNotification({ msg: res.message, isError: true, icon: 'fad fa-exclamation-circle' });
      }
      focusElement('notification_title');
      this.password = '';
      this.fetchUsers();
    },

    async resetUser(id) {
      const params = {
        id,
        password: this.password,
        isDeactivate: this.fullAccountDeactivation,
      };

      this.closeEditModal();
      const res = await this.accountsService.resetUser(params, this.headers);
      if (res.success) {
        this.showNotification({ msg: res.message, icon: 'fad fa-check-circle' });
      } else {
        this.showNotification({ msg: res.message, isError: true, icon: 'fad fa-exclamation-circle' });
      }
      focusElement('notification_title');
      this.password = '';
      this.fullAccountDeactivation = false;
      this.fetchUsers();
    },

    async suspendStudent(id) {
      const params = {
        id: id,
      };

      await this.studentService.suspendStudent(params, this.headers);

      this.fetchUsers();
    },

    async suspendMultipleStudents(action: string) {
      const params = {
        ids: this.selected,
        action: action,
      };

      await this.studentService.suspendMultiple(params, this.headers);

      this.fetchUsers();
    },

    async deleteMultipleUsers(password) {
      if (password) {
        this.password = password;
      }
      const params = {
        ids: this.selected,
        password: this.password,
      };
      const result = await this.studentService.deleteMultiple(params, this.headers);
      this.password = '';
      if (result.success) {
        this.fetchUsers();
        this.showNotification({ msg: 'Users deleted successfully.', icon: 'fad fa-check-circle' });
      } else {
        this.showNotification({
          msg: 'Multiple users deletion failed.',
          isError: true,
          icon: 'fad fa-exclamation-circle',
        });
      }
      this.closeMultiDeleteModal();
    },

    async handleExport(fileType: string) {
      const selected = this.selected.length > 0 ? this.selected : [];
      const students = selected.join(',');
      const studentsParam = students ? students : '[]';

      await this.exportService.downloadFile(fileType, studentsParam, this.headers, `export.${fileType}`);
    },

    openBulkModal() {
      this.isBulkModalActive = true;
      this.hasImported = false;
      focusElement('upload_modal');
    },

    closeBulkModal() {
      this.isBulkModalActive = false;

      this.searchInput = '';

      if (this.hasImported) {
        this.fetchUsers();
      }
    },

    userToSelectObjectMapper(user) {
      return {
        id: user.id,
        name: user.first_name + ' ' + user.last_name,
        unique_signature: user.email,
        icon: user.profile_picture,
      };
    },

    async loadUsers(searchString = '', page = 1) {
      if (page == 1) this.usersList = [];
      this.isLoadingSelectUsers = true;
      this.selectUserPage = page;
      let response = await this.accountsService.getUsers({
        name: searchString,
        page: page,
      });
      this.usersList = this.usersList.concat(response.page_data.map((user) => this.userToSelectObjectMapper(user)));
      this.selectUserTotalPageNumber = response.total_pages_number;
      if (this.selectUserTotalPageNumber == this.selectUserPage) {
        this.isLoadingSelectUsers = false;
      }
      this.selectUserCurrentPage = page;
    },

    async openUsersSelectModal() {
      this.isUsersSelectModalActive = true;
      this.loadUsers('', 1);
    },

    closeUsersSelectModal(data) {
      this.isUsersSelectModalActive = false;
    },

    changeUploadStatus() {
      this.hasImported = true;
    },

    openManualModal() {
      this.isManualModalActive = true;
      focusElement('modal_manual_add_student');
    },

    closeManualModal() {
      this.isManualModalActive = false;
      this.preselectedRole = undefined;
      this.studentForEdit = null;
    },

    closeEditModal() {
      this.isEditModalActive = false;
      this.preselectedAction = '';
    },
    resetPasswordHandler(user) {
      this.resetPassword(user.email);
      this.closeEditModal();
    },
    resetUserHandler(user) {
      this.password = user.password;
      this.fullAccountDeactivation = user.deactivateAccount;
      this.resetUser(user.id);
    },
    deleteUserHandler(user) {
      this.password = user.password;
      this.deleteStudent(user.id);
    },
    async editUserHandler(user) {
      const DTO = {
        id: user.id,
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        role: user.role.name,
        department: user.department,
        disabled: user.disabled,
      };
      this.preselectedRole = undefined;
      await this.accountsService
        .editUser(DTO, DTO.id)
        .then((response) => {
          this.fetchUsers();
          this.showNotification({ msg: 'User successfully edited.', icon: 'fad fa-check-circle' });
          this.$refs.editUserModal.closeEditModal(true);
        })
        .catch((error) => {
          if (error.responseObject && error.responseObject.error) {
            switch (error.responseObject.error) {
              case 'email_already_exist':
                this.showNotification({
                  msg: 'This email is already in use.',
                  isError: true,
                  icon: 'fad fa-exclamation-circle',
                });
                break;
              default:
                this.showNotification({ msg: 'Error Updating User', isError: true, icon: 'fad fa-exclamation-circle' });
                break;
            }
          } else {
            this.showNotification({ msg: 'Error Updating User', isError: true, icon: 'fad fa-exclamation-circle' });
          }
        });
      this.searchInput = '';
    },
    openAddUserModal() {
      this.isAddModalActive = true;
    },
    closeAddUserModal() {
      this.isAddModalActive = false;
    },
    addUserHandler(user) {
      const params = {
        studentObject: {
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          role: user.role.name,
          department: user.department,
        },
        sendInvite: user.sendConfirmationEmail,
        isSSO: user.isSSO,
        is_edit_user: false,
      };

      this.addStudent(params);
    },
    async getRoleData(roleName) {
      let role;
      if (roleName === 'CUSTOM') {
        role = this.studentForEdit.role;
      } else {
        role = this.roles.find((e) => e.name == roleName);
      }
      this.roleData = null;
      this.roleData = await this.roleService.getRoleData({ role: role.id });
      this.roleData.readonly = !(roleName === 'CUSTOM');
    },
    async openEditRoleModal(student) {
      const sidebar: HTMLElement = document.querySelector('#sidebar');
      sidebar.style.display = 'none';
      this.isEditRoleModalActive = true;
      focusElement('modal_edit_role');
      this.availableRoles = [...this.roles, { name: 'CUSTOM', id: 99999 }];
      this.roleData = await this.roleService.getRoleData({ role: student.role.id });
      this.roleData.readonly = !(student.role.name === 'CUSTOM');
    },

    closeEditRoleModal(refetchUsers = false) {
      const sidebar: HTMLElement = document.querySelector('#sidebar');
      sidebar.style.display = 'block';
      this.isEditRoleModalActive = false;
      this.studentForEdit = null;
      this.preselectedRole = undefined;
      this.selectedRole = undefined;
      this.roleData = null;
      if (refetchUsers) {
        this.fetchUsers();
        this.showNotification({ msg: 'Role updated successfully.', icon: 'fad fa-check-circle' });
      }
    },

    openDeleteModal(student) {
      this.studentForEdit = student;
      this.openEditUserModal('CONFIRM_DELETE_USER');
    },
    openMultiDeleteModal() {
      this.openEditUserModal('CONFIRM_DELETE_MULTIPLE_USERS');
    },
    openResetUserModal(user) {
      this.userToReset = user;
      this.isResetUserModalActive = true;
    },

    closeDeleteModal() {
      focusElement(`more_option_managa_accounts_${this.studentForDelete.id}`);
      this.studentForDelete = null;
      this.isDeleteModalActive = false;
    },
    closeMultiDeleteModal() {
      this.isMultiDeleteModalActive = false;
      this.isEditModalActive = false;
    },

    openExportMenu() {
      const menuEl = document.querySelector('#export-menu');

      menuEl.classList.toggle('eu-resource-burger-active');
    },

    closeExportMenu() {
      const menuEl = document.querySelector('#export-menu');

      menuEl.classList.remove('eu-resource-burger-active');
    },
    filterData(value, name) {
      if (name === 'Departments') {
        this.selectedDepartments = value.map((v) => encodeURIComponent(v.name));
      } else if (name === 'Roles') {
        this.selectedRoles = value.map((v) => encodeURIComponent(v.name));
      } else if (name === 'Status') {
        this.selectedStatuses = value.map((v) => encodeURIComponent(v.name));
      } else if (name === 'Audience'){
        this.selectedAudiences = value.map((v) => encodeURIComponent(v.id));
      }
      this.isSearching = true;
      this.fetchUsers();
    },
  },
};
</script>
<style scoped>
.accounts-title-wrapper {
  padding: 24px 0;
}
.button-container {
  display: flex;
  align-items: flex-end;
}

.no-users-container {
  text-align: center;
  margin-top: 20px;
}

.no-users-container p {
  font-size: 18px;
  line-height: 1.67;
  color: #828282;
}

.large-icon {
  font-size: 220px;
  opacity: 0.6;
  color: #d0c2ff;
}

.small-icon {
  font-size: 40px;
  opacity: 0.6;
  color: #d0c2ff;
}

.delete-modal-header {
  margin-left: 15px;
  font-size: 18px;
  font-weight: 500;
  color: #666666;
}

.delete-text {
  margin-top: 20px;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 10px;
  text-align: center;
  font-size: 16px;
  line-height: 1.56;
  color: #707070;
}

.container {
  height: 100%;
  width: 100%;
  height: 100%;
  padding-left: 30px;
  padding-right: 30px;
  margin-left: 50px;
  margin-right: 50px;
}

.eu-input-container {
  position: relative;
  margin-top: 21px;
  margin-bottom: 31px;
}

.eu-input-container.last {
  margin-bottom: 20px;
}

.eu-input {
  border: 3px solid #949494;
  padding-left: 10px;
  border-radius: 10px;
  width: 100%;
  height: 50px;
  background-color: #ffffff;
  outline: none;
  color: #707070;
  font-size: 16px;
}

.eu-input:focus {
  border: solid 6px #d0c2ff;
}

.label-container {
  position: absolute;
  bottom: 39px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  left: 8px;
  padding: 0 8px;
  -webkit-transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 2;
  width: 100%;
}

.label-container label {
  font-size: 13px;
  font-weight: 500;
  color: #4c55a7;
  background: #ffffff;
  padding: 0px 6px 0px 10px;
  text-align: left;
}

.label-container span {
  font-weight: 600;
  color: #e65722;
  padding: revert;
}

.button-container {
  margin-bottom: 30px;
}

.checkbox-content-container {
  margin-left: auto;
  margin-right: auto;
  max-width: fit-content;
  margin-bottom: 21px;
  max-height: fit-content;
}

.error {
  color: #e65722;
}

@media only screen and (max-width: 768px) {
  .button-container {
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
