<template>
  <div class="eu-form-container inup-form-container eu-form-page">
    <form class="eu-form inup-form" @submit.prevent="create()" id="eu-form" enctype="multipart/form-data">
      <div class="eu-form-section inup-form-section">
        <div>
          <h2>Opportunity Photo</h2>
          <p class="thumbnail-desc">
            Use a landscape orientation image for best results.
            <br />
            Suggested image dimensions: 3:2 ratio or 1080x720.
          </p>
          <dropzone-component
            v-if="imageInput"
            :imageSrc="program.thumbnail"
            :imageInput="imageInput"
            :maxImageSize="image_size_data.max_size"
            :maxImageSizeFormat="image_size_data.formatted"
            @uploadImage="prepareUpload"
            @removeImage="handleRemoveImage"
          />
          <input
            type="file"
            name="thumbnail"
            id="thumbnail"
            class="js-input-file-control da-components-hidden-file-control"
            accept="image/*"
            maxlength="400"
          />
        </div>
      </div>
      <div class="eu-form-section inup-form-section">
        <div class="eu-form-element-container-no-bot-margin">
          <input class="inup-input" type="text" name="title" required v-model="program.title" />
          <div class="inup-form-label-container">
            <label for="title">Opportunity Title<span> *</span></label>
          </div>
          <!-- error -->
        </div>
        <span id="title-counter" class="character-counter">{{ program.title.length }}/80</span>
        <div class="eu-form-description-container">
          <textarea
            name="description"
            cols="40"
            rows="10"
            class="inup-textarea-input vertical"
            v-model="program.description"
          ></textarea>
          <div class="inup-textarea-label-container">
            <label for="description">Description<span> *</span></label>
          </div>
          <!-- error -->
        </div>
        <div :class="isPartnerTenant || group ? '' : 'multiple-elements'">
          <div class="eu-form-element-container" style="width: 100%">
            <select name="type" class="inup-input" v-model="program.type" required>
              <option value selected>Select from list</option>
              <option v-for="option in type" v-bind:value="option.name" v-bind:key="option.name">
                {{ option.name }}
              </option>
            </select>
            <div class="inup-form-label-container">
              <label for="type">Opportunity Type<span> *</span></label>
            </div>
            <!-- error -->
          </div>
          <div v-if="!isPartnerTenant && !group" class="eu-form-element-container" style="width: 100%">
            <select name="department" class="inup-input" v-model="program.department" required>
              <option value selected>Select from list</option>
              <option v-for="option in departments" v-bind:value="option.id" v-bind:key="option.id">
                {{ option.name }}
              </option>
            </select>
            <div class="inup-form-label-container">
              <label for="department">{{ deptSingular }}<span> *</span></label>
            </div>
            <!-- error -->
          </div>
        </div>
        <div v-if="!isJobIntershipProgram" class="eu-form-element-container-no-bot-margin" style="margin-bottom: 30px; margin-top: 0">
          <input class="inup-input" type="url" name="url" v-model="program.external_link" />
          <div class="inup-form-label-container">
            <label for="name">
              External Webpage/URL <span class="primary-color">(example: https://www.beglobalii.com)</span>
            </label>
          </div>
          <!-- error -->
        </div>
      </div>
      <div class="eu-form-section inup-form-section">
        <h2 class="header-two-margin-bot">Opportunity details</h2>

        <div v-if="isGlobalProgram" class="eu-form-element-container" style="width: 100%">
          <select name="term" class="inup-input" v-model="program.term" required>
            <option value="" selected disabled>Select from list</option>
            <option value="Summer">Summer</option>
            <option value="Fall">Fall</option>
            <option value="Winter">Winter</option>
            <option value="Spring">Spring</option>
          </select>
          <div class="inup-form-label-container">
            <label for="term">Term<span> *</span></label>
          </div>
          <!-- error -->
        </div>

        <template v-if="isJobIntershipProgram">
          <div class="eu-form-element-container" style="width: 100%">
            <select name="employment_type" class="inup-input" v-model="program.employment_type" required>
              <option value="" selected disabled>Select from list</option>
              <option value="Full-time">Full-time</option>
              <option value="Part-time">Part-time</option>
              <option value="Contractor/Freelancer">Contractor/Freelancer</option>
            </select>
            <div class="inup-form-label-container">
              <label for="employment_type">Employment Type<span> *</span></label>
            </div>
            <!-- error -->
          </div>

          <div class="eu-form-element-container" style="width: 100%">
            <h4 class="radio-btn-label">Is This a Job or an Internship <span> *</span></h4>
            <p class="permission-p">
              <label class="radio-container">
                Job
                <input
                  type="radio"
                  name="internships_and_jobs_type"
                  value="Job"
                  v-model="program.internships_and_jobs_type"
                  required
                />
                <span class="radio-checkmark"></span>
              </label>
            </p>
            <p class="permission-p">
              <label class="radio-container">
                Internship
                <input
                  type="radio"
                  name="internships_and_jobs_type"
                  value="Internship"
                  v-model="program.internships_and_jobs_type"
                  required
                />
                <span class="radio-checkmark"></span>
              </label>
            </p>
          </div>

          <div class="eu-form-element-container" style="width: 100%">
            <multiselect-component
              :options="language_requirements"
              :preselected="preselected_language_requirements"
              name="language_requirements"
              @handle-change="handleChange"
            />
            <div class="inup-form-label-container">
              <label for="type">Language Requirements</label>
            </div>
            <!-- error -->
          </div>

          <div class="multiple-elements">
            <div class="eu-form-element-container" style="width: 100%; margin-top: 0">
              <input class="inup-input" type="number" name="salary_amount" v-model="program.salary_amount" />
              <div class="inup-form-label-container">
                <label for="salary_amount">Salary Amount</label>
              </div>
              <!-- error -->
            </div>

            <div class="eu-form-element-container" style="width: 100%; margin-top: 0">
              <select name="salary_currency" class="inup-input" v-model="program.salary_currency">
                <option v-for="currency in currencies" v-bind:value="currency" v-bind:key="currency">
                  {{ currency }}
                </option>
              </select>
              <div class="inup-form-label-container">
                <label for="salary_currency">Salary Currency</label>
              </div>
              <!-- error -->
            </div>
          </div>

          <div class="eu-form-element-container" style="width: 100%">
            <select name="salary_frequency" class="inup-input" v-model="program.salary_frequency">
              <option value="" selected disabled>Select from list</option>
              <option value="year">Year</option>
              <option value="hour">Hour</option>
              <option value="day">Day</option>
              <option value="week">Week</option>
              <option value="month">Month</option>
              <option value="other">Other</option>
            </select>
            <div class="inup-form-label-container">
              <label for="salary_frequency">Salary Frequency</label>
            </div>
            <!-- error -->
          </div>

          <div v-if="program.salary_frequency === 'other'" class="eu-form-element-container" style="width: 100%">
            <input
              class="inup-input"
              type="text"
              name="salary_frequency_other"
              v-model="program.salary_frequency_other"
            />
            <div class="inup-form-label-container">
              <label for="salary_frequency_other">Specify Other Frequency</label>
            </div>
            <!-- error -->
          </div>

          <div class="eu-form-description-container" style="width: 100%">
            <textarea
              name="benefits"
              cols="40"
              class="inup-textarea-input vertical"
              v-model="program.benefits"
            ></textarea>
            <div class="inup-textarea-label-container">
              <label for="benefits">Benefits</label>
            </div>
            <!-- error -->
          </div>
        </template>

        <div class="multiple-elements">
          <div class="eu-form-element-container" style="width: 100%; margin-top: 0">
            <datetime
              type="date"
              class="datetime-theme-blues"
              v-model="program.start_date"
              input-class="inup-input"
              :format="{ year: 'numeric', month: 'long', day: 'numeric' }"
              :phrases="{ ok: 'Submit', cancel: 'Cancel' }"
              :max-datetime="program.end_date ? new Date(program.end_date).toISOString() : null"
              :min-datetime="new Date().toISOString()"
              :week-start="7"
              title="Start Date"
              use12-hour
              value-zone="UTC"
              zone="UTC"
            ></datetime>
            <div class="inup-form-label-container">
              <label for="cost">Start Date</label>
            </div>
            <!-- error -->
          </div>
          <div class="eu-form-element-container" style="width: 100%; margin-top: 0">
            <datetime
              type="date"
              class="datetime-theme-blues"
              v-model="program.end_date"
              input-class="inup-input"
              :format="{ year: 'numeric', month: 'long', day: 'numeric' }"
              :phrases="{ ok: 'Submit', cancel: 'Cancel' }"
              :min-datetime="program.start_date ? new Date(program.start_date).toISOString() : new Date().toISOString()"
              :week-start="7"
              title="End Date"
              use12-hour
              value-zone="UTC"
              zone="UTC"
            ></datetime>
            <div class="inup-form-label-container">
              <label for="cost">End Date</label>
            </div>
            <!-- error -->
          </div>
        </div>
        <div class="multiple-elements">
          <div class="eu-form-element-container" style="width: 100%; margin-top: 0">
            <datetime
              type="date"
              class="datetime-theme-blues"
              v-model="program.deadline"
              input-class="inup-input"
              :format="{ year: 'numeric', month: 'long', day: 'numeric' }"
              :phrases="{ ok: 'Submit', cancel: 'Cancel' }"
              :min-datetime="new Date().toISOString()"
              :week-start="7"
              title="Deadline"
              use12-hour
              value-zone="UTC"
              zone="UTC"
            ></datetime>
            <div class="inup-form-label-container">
              <label for="cost">Application Deadline <span> *</span></label>
            </div>
            <!-- error -->
          </div>

          <div v-if="!isJobIntershipProgram" class="eu-form-element-container" style="width: 100%; margin-top: 0">
            <input class="inup-input" type="number" name="cost" v-model="program.cost" />
            <div class="inup-form-label-container">
              <label for="cost">Opportunity Cost</label>
            </div>
            <!-- error -->
          </div>
          <div v-else class="eu-form-element-container" style="width: 100%; margin-top: 0"></div>
        </div>

<!--        <div-->
<!--          v-if="isPartnerTenant"-->
<!--          class="eu-form-element-container-no-bot-margin"-->
<!--          style="margin-top: 0px; margin-bottom: 22px"-->
<!--        >-->
<!--          <input class="inup-input" type="url" name="Link" v-model="program.apply_url" />-->
<!--          <div class="inup-form-label-container">-->
<!--            <label for="Link">Apply URL <span class="primary-color">(example: https://www.beglobalii.com)</span></label>-->
<!--          </div>-->
<!--          &lt;!&ndash; error &ndash;&gt;-->
<!--        </div>-->

        <!-- <div
          v-if="isPartnerTenant"
          class="eu-form-element-container-no-bot-margin"
          style="margin-bottom: 22px; margin-top: 10"
        >
          <input class="inup-input" type="url" name="Link" v-model="program.more_info_url" />
          <div class="inup-form-label-container">
            <label for="Link"
              >More Info URL <span class="primary-color">(example: https://www.beglobalii.com)</span></label
            >
          </div>
        </div> -->

        <!-- <div class="eu-form-element-container" style="margin-top: 0">
          <multiselect-component
            :options="focus_areas"
            :preselected="preselected_focus_areas"
            name="focus_areas"
            :max="5"
            @handle-change="handleChange"
          />
          <div class="inup-form-label-container">
            <label for="type">Focus Area</label>
          </div>
        </div> -->

        <div v-if="isPartnerTenant" class="eu-form-element-container" style="margin-top: 0">
          <multiselect-component
            :options="destinations"
            :preselected="preselected_destinations"
            name="destinations"
            :max="5"
            @handle-change="handleChange"
          />
          <div class="inup-form-label-container">
            <label for="type">Locations</label>
          </div>
          <!-- error -->
        </div>

        <div class="eu-form-element-container">
          <multiselect-component
            :options="identities"
            :preselected="preselected_identities"
            name="identities"
            :max="5"
            @handle-change="handleChange"
          />
          <div class="inup-form-label-container">
            <label for="type">Identity Groups</label>
          </div>
          <!-- error -->
        </div>
        <div class="eu-form-element-container">
          <multiselect-component
            :options="audiences"
            :preselected="preselected_audiences"
            name="audiences"
            :max="5"
            @handle-change="handleChange"
          />
          <div class="inup-form-label-container">
            <label for="type">Audiences</label>
          </div>
          <!-- error -->
        </div>
        <div class="eu-form-element-container">
          <multiselect-component
            :options="career_academic_interests_options"
            :preselected="preselected_career_academic"
            name="career_academic_interests"
            :max="5"
            @handle-change="handleChange"
          />
          <div class="inup-form-label-container">
            <label for="type">Career/Academic interests</label>
          </div>
          <!-- error -->
        </div>
      </div>
      <div class="eu-form-section inup-form-section" style="padding-bottom: 30px">
        <h2 class="header-two-margin-bot">Contact Details</h2>

        <div class="eu-form-element-container" style="width: 100%">
          <p class="permission-p">
            <label class="radio-container">
              Apply URL
              <input type="radio" name="contact_details" value="apply-url" v-model="contact_details" />
              <span class="radio-checkmark"></span>
            </label>
          </p>
          <p v-if="!isJobIntershipProgram" class="permission-p">
            <label class="radio-container">
              Contact Us Email Address
              <input type="radio" name="contact_details" value="contact-us-email-address" v-model="contact_details" />
              <span class="radio-checkmark"></span>
            </label>
          </p>
        </div>

        <div
          v-if="contact_details === 'apply-url'"
          class="eu-form-element-container"
          style="width: 100%; margin-top: 0"
        >
          <input
            :required="isJobIntershipProgram"
            class="inup-input"
            type="text"
            name="apply_url"
            v-model="program.apply_url"
          />
          <div class="inup-form-label-container">
            <label for="apply_url">Apply URL <span class="primary-color">(example: https://www.beglobalii.com)</span>
              <span v-if="isJobIntershipProgram">*</span>
            </label>
          </div>
          <!-- error -->
        </div>

        <div
          v-if="contact_details === 'contact-us-email-address'"
          class="eu-form-element-container"
          style="width: 100%; margin-top: 0"
        >
          <input
            class="inup-input"
            type="text"
            name="contact_us_email_address"
            v-model="program.contact_us_email_address"
          />
          <div class="inup-form-label-container">
            <label for="contact_us_email_address">Contact Us Email Address</label>
          </div>
          <!-- error -->
        </div>
      </div>
      <div class="eu-form-section inup-form-section" style="padding-bottom: 30px; display: flex">
        <div style="width: 80%">
          <h2 class="header-two-margin-bot" style="margin-bottom: 0">Opportunity Contacts</h2>
          <p>Add opportunity leaders, TAs, etc.</p>
        </div>
        <div style="width: 20%; text-align: right">
          <span
            @click="openAddContactModal()"
            @keyup.enter="openAddContactModal()"
            aria-label="Open modal for adding contacts"
            aria-haspopup=""
            role="dialog"
            class="open-modal-text"
          >
            Add contact <i class="far fa-plus"></i>
          </span>
          <p v-if="program.program_contacts.length !== 0" style="color: #00a1b3">
            <b>{{ program.program_contacts.length }}</b> contacts added
          </p>
        </div>
      </div>
      <div class="eu-form-section inup-form-section" style="padding-bottom: 30px">
        <h2 class="header-two-margin-bot">Location</h2>
        <!-- <div style="display: flex">
          <div class="selection-tabs-container">
            <span
              tabindex="0"
              @click="isSelected(true, 'is_campus')"
              @keyup.enter="isSelected(true, 'is_campus')"
              role="button"
              aria-label="Program, in person/on campus"
              class="selection-tab"
              :class="this.program.is_campus ? `active` : ``"
            >
              {{ isPartnerTenant ? "In-Person" : "On Campus" }}
            </span>
            <span
              tabindex="0"
              @click="isSelected(false, 'is_campus')"
              @keyup.enter="isSelected(false, 'is_campus')"
              aria-label="Program, virtual/off campus"
              role="button"
              class="selection-tab"
              :class="!this.program.is_campus ? `active` : ``"
            >
              {{ isPartnerTenant ? "Virtual" : "Off Campus" }}
            </span>
          </div>
          <span style="align-self: center; color: #e65722"> *</span>
        </div> -->
        <div style="margin-top: 10px">
          <button
            id="btn-virtual"
            type="button"
            :class="!program.is_hybrid && program.is_online ? 'location-button active' : 'location-button'"
            @click="isVirtual(true)"
          >
            Virtual
          </button>
          <button
            id="btn-venue"
            type="button"
            :class="!program.is_hybrid && !program.is_online ? 'location-button active' : 'location-button'"
            @click="isVirtual(false)"
          >
            Venue
          </button>
          <button
            id="btn-venue"
            type="button"
            :class="program.is_hybrid ? 'location-button active' : 'location-button'"
            @click="setIsHybrid(true)"
          >
            Hybrid
          </button>
        </div>
        <!-- <div
          v-if="program.is_hybrid"
          class="eu-form-element-container-no-bot-margin"
          style="margin-bottom: 10px; margin-top: 10"
        >
          <input
            class="inup-input"
            type="url"
            name="Link"
            placeholder="Opportunity link (e.g., Zoom link, livestream link, etc.)"
            v-model="program.online_url"
          />
          <div class="inup-form-label-container">
            <label for="Link">Link <span class="primary-color">(example: https://www.beglobalii.com)</span></label>
          </div>
        </div> -->

        <div v-if="!program.is_online || program.is_hybrid">
          <div class="eu-form-element-container-no-bot-margin">
            <input class="inup-input" type="text" name="address_1" v-model="program.address_1" />
            <div class="inup-form-label-container">
              <label for="address_1">Address</label>
            </div>
            <!-- error -->
          </div>
          <div class="eu-form-element-container-no-bot-margin">
            <input class="inup-input" type="text" name="address_2" v-model="program.address_2" />
            <div class="inup-form-label-container">
              <label for="address_2">Address Two</label>
            </div>
            <!-- error -->
          </div>
          <div class="eu-form-element-container-no-bot-margin">
            <input class="inup-input" type="text" name="city" v-model="program.city" />
            <div class="inup-form-label-container">
              <label for="city">City</label>
            </div>
            <!-- error -->
          </div>
          <div class="eu-form-element-container" style="width: 100%">
            <select name="type" class="inup-input" v-model="program.country">
              <option v-for="(val, index) in country" v-bind:value="index" v-bind:key="index">
                {{ val }}
              </option>
            </select>
            <div class="inup-form-label-container">
              <label for="type">Country</label>
            </div>
            <!-- error -->
          </div>
          <div v-if="program.country == 'USA'" class="eu-form-element-container" style="width: 100%">
            <select name="type" class="inup-input" v-model="program.state">
              <option v-for="(val, index) in state" v-bind:value="index" v-bind:key="index">
                {{ val }}
              </option>
            </select>
            <div class="inup-form-label-container">
              <label for="type">State</label>
            </div>
            <!-- error -->
          </div>
          <div class="eu-form-element-container-no-bot-margin">
            <input class="inup-input" type="text" name="postal_code" v-model="program.postal_code" />
            <div class="inup-form-label-container">
              <label for="postal_code">Zip Code</label>
            </div>
            <!-- error -->
          </div>
        </div>
      </div>

      <div class="eu-form-section inup-form-section" style="padding-bottom: 30px">
        <h2 class="header-two-margin-bot" style="margin-bottom: 0">Files</h2>
        <p>Files must be no larger than 10MB.</p>
        <div class="upload-file-form">
          <div v-if="error" class="row">
            <p class="error">{{ error }}</p>
          </div>
          <div
            v-for="file in files"
            v-bind:key="file.name"
            class="row upload-file-container iu-upload-modal-file-container"
            style="width: 100%"
          >
            <div v-if="file.status !== 'deleted'" class="iu-upload-modal-file-status-container">
              <span
                v-if="file.file_type == 'IMG'"
                class="uploaded-image-preview"
                style="height: 32px; background: round; width: 40px"
                :style="{ backgroundImage: 'url(' + file.file_url + ')' }"
              ></span>
              <i v-if="file.file_type == 'DOC'" class="fas fa-file-upload iu-upload-modal-file-type-icon"></i>
              <div class="iu-upload-modal-file-name-container">
                <div class="iu-upload-modal-file-name-text" style="white-space: normal">
                  {{ file.name }}
                  <span class="iu-upload-modal-file-status-text">({{ file.status }})</span>
                </div>
                <div>
                  <span
                    v-if="file.status == 'uploading'"
                    class="iu-upload-modal-status-bar iu-upload-modal-status-bar-animated"
                  ></span>
                  <span
                    v-if="file.status == 'pending'"
                    class="iu-upload-modal-status-bar iu-upload-modal-status-bar-animated line-pending"
                    style="background-position: 0"
                  ></span>
                  <span v-if="file.status == 'failed'" class="iu-upload-modal-status-bar line-failed"></span>
                  <span v-if="file.status == 'success'" class="iu-upload-modal-status-bar line-success"></span>
                </div>
              </div>
              <button
                class="iu-upload-modal-file-delete-button"
                :aria-label="'Unselect file ' + file.name"
                @click="removeFile(file)"
              >
                <i class="fa-solid fa-trash-can"></i>
              </button>
            </div>
          </div>
          <div class="row upload-file-container">
            <div class="column" style="width: 100%">
              <div
                v-cloak
                @drop.prevent="handleFileUpload"
                @dragover.prevent
                class="innerRow dnd-wrapper"
                style="align-items: center"
              >
                <label
                  @dragenter="dragging = true"
                  @dragleave="dragging = false"
                  class="file-upload-label"
                  style="width: 100%"
                  :style="{ background: dragging ? '#eaeeff' : '' }"
                  for="resource_image"
                >
                  <span>
                    <i class="fad fa-cloud-upload-alt dnd-upload-icon"></i> <br />
                    Drag &amp; drop files here <br />
                    or <br />
                    <input @change="handleFileUpload" id="resource_image" type="file" style="width: 0" multiple />
                    <span class="file-input-text">
                      Browse from device <i class="fal fa-angle-right" style="color: #5e50b5; font-size: 15px"></i>
                    </span>
                  </span>
                </label>
              </div>
            </div>
            <label
              id="acceptedFileTypes"
              class="eu-checkbox-container"
              style="padding-left: 0; margin-top: 25px; text-align: center; color: #707070"
              >Accepted file types: <br />
              <span style="font-weight: 500" aria-labelledby="acceptedFileTypes">
                .pdf, .doc, .xls, .ppt, .jpg, .png, .gif, .webp
              </span>
            </label>
          </div>
        </div>
      </div>
      <div class="eu-form-section inup-form-section" style="padding-bottom: 30px">
        <div style="width: 100%; display: flex">
          <h2 class="header-two-margin-bot" style="margin-bottom: 0">Add internal/external URL or Embed video</h2>
          <div v-if="resource_counter != 0" style="color: #00a1b3; width: 48%; text-align: right">
            <b>{{ resource_counter }}</b> resources added
          </div>
        </div>
        <div v-if="error_url_message" class="row">
          <p class="error">{{ error_url_message }}</p>
        </div>
        <div class="eu-form-element-container-no-bot-margin" style="margin-bottom: 10px; margin-top: 10">
          <input class="inup-input" type="url" name="Link" placeholder="Link" v-model="resource_url.file_url" />
          <div class="inup-form-label-container">
            <label for="Link">Link <span class="primary-color">(example: https://www.beglobalii.com)</span></label>
          </div>
          <!-- error -->
        </div>
        <div class="eu-form-element-container-no-bot-margin" style="margin-bottom: 10px; margin-top: 10">
          <input class="inup-input" type="text" name="name" placeholder="File name" v-model="resource_url.name" />
          <div class="inup-form-label-container">
            <label for="name">File Name</label>
          </div>
          <!-- error -->
        </div>
        <div class="eu-form-element-container" style="width: 100%">
          <select name="type_of_url" class="inup-input" v-model="resource_url.file_type">
            <option value selected>Select from list</option>
            <option value="URL">Internal/external url</option>
            <option value="VID">Embeded Video</option>
          </select>
          <div class="inup-form-label-container">
            <label for="type_of_url">Resource type</label>
          </div>
          <!-- error -->
        </div>
        <button
          type="button"
          id="btn-add-url"
          class="iu-main-btn main small outlined"
          aria-label="Add resource"
          @click="addUrl()"
        >
          Add resource
        </button>
      </div>
      <div v-if="isAdmin" class="eu-form-section inup-form-section feature-section" style="">
        <h2 class="header-two-margin-bot" style="margin: 0; align-self: center">Feature in Opportunities</h2>
        <!-- <div style="display: flex">
          <div class="selection-tabs-container">
            <span
              tabindex="0"
              @click="isSelected(false, 'is_hero_banner')"
              @keyup.enter="isSelected(false, 'is_hero_banner')"
              role="button"
              aria-label="Reguarl listing"
              class="selection-tab"
              :class="!this.program.is_hero_banner ? `active` : ``"
            >
              Regular listing
            </span>
            <span
              tabindex="0"
              aria-label="Hero banner"
              @click="isSelected(true, 'is_hero_banner')"
              @keyup.enter="isSelected(true, 'is_hero_banner')"
              role="button"
              class="selection-tab"
              :class="this.program.is_hero_banner ? `active` : ``"
            >
              Hero banner
            </span>
          </div>
          <span class="tooltip-container" style="align-self: center">
            <i class="fas fa-question-circle tooltip-icon left-margin-small" aria-hidden="true"></i>
            <span class="tooltip medium right" style="left: 39%; top: 100%">
              Adds this opportunity to the hero carousel in the Events &nbsp; Programs landing page. There is a limit of 3 hero items.
            </span>
          </span>
        </div> -->
        <div style="display: flex">
          <div class="selection-tabs-container">
            <span
              tabindex="0"
              aria-label="Unfeature"
              @click="isSelected(false, 'is_featured')"
              @keyup.enter="isSelected(false, 'is_featured')"
              role="button"
              class="selection-tab"
              :class="!this.program.is_featured ? `active` : ``"
            >
              Unfeatured
            </span>
            <span
              tabindex="0"
              aria-label="Feature"
              @click="isSelected(true, 'is_featured')"
              @keyup.enter="isSelected(true, 'is_featured')"
              role="button"
              class="selection-tab"
              :class="this.program.is_featured ? `active` : ``"
            >
              Feature
            </span>
          </div>
          <span class="tooltip-container" style="align-self: center">
            <i class="fas fa-question-circle tooltip-icon left-margin-small" aria-hidden="true"></i>
            <span class="tooltip medium right">
              Adds a blue “Featured” star to the listing. Featured programs appear first in recommendations and results.
            </span>
          </span>
        </div>
      </div>
      <div v-if="isAdmin" class="eu-form-section inup-form-section feature-section" style="">
        <h2 class="header-two-margin-bot" style="margin: 0; align-self: center">Feature on Dashboard</h2>
        <div style="display: flex">
          <div class="selection-tabs-container">
            <span
              tabindex="0"
              aria-label="Unfeature"
              @click="isSelected(false, 'is_dashboard_featured')"
              @keyup.enter="isSelected(false, 'is_dashboard_featured')"
              role="button"
              class="selection-tab"
              :class="!this.program.is_dashboard_featured ? `active` : ``"
            >
              Unfeatured
            </span>
            <span
              tabindex="0"
              aria-label="Feature"
              @click="isSelected(true, 'is_dashboard_featured')"
              @keyup.enter="isSelected(true, 'is_dashboard_featured')"
              role="button"
              class="selection-tab"
              :class="this.program.is_dashboard_featured ? `active` : ``"
            >
              Feature
            </span>
          </div>
          <span class="tooltip-container" style="align-self: center">
            <i class="fas fa-question-circle tooltip-icon left-margin-small" aria-hidden="true"></i>
            <span class="tooltip medium right"> Features listing on students' dashboard </span>
          </span>
        </div>
      </div>
      <div v-if="partner" class="eu-form-section inup-form-section feature-section" style="">
        <h2 class="header-two-margin-bot" style="margin: 0; align-self: center">Feature on {{ partner }}</h2>
        <div style="display: flex">
          <div class="selection-tabs-container">
            <span
              tabindex="0"
              aria-label="Unfeature"
              @click="isSelected(false, 'is_partner_featured')"
              @keyup.enter="isSelected(false, 'is_partner_featured')"
              role="button"
              class="selection-tab"
              :class="!this.program.is_partner_featured ? `active` : ``"
            >
              Unfeatured
            </span>
            <span
              tabindex="0"
              aria-label="Feature"
              @click="isSelected(true, 'is_partner_featured')"
              @keyup.enter="isSelected(true, 'is_partner_featured')"
              role="button"
              class="selection-tab"
              :class="this.program.is_partner_featured ? `active` : ``"
            >
              Feature
            </span>
          </div>
          <span class="tooltip-container" style="align-self: center">
            <i class="fas fa-question-circle tooltip-icon left-margin-small" aria-hidden="true"></i>
            <span class="tooltip medium right">
              Adds a blue “Featured” star to the listing inside Partner's Landing Page. Featured programs always appear
              first.
            </span>
          </span>
        </div>
      </div>
      <div v-if="partner && isAdmin" class="eu-form-section inup-form-section feature-section" style="">
        <h2 class="header-two-margin-bot" style="margin: 0; align-self: center">Visibility</h2>
        <div style="display: flex">
          <div class="selection-tabs-container">
            <span
              tabindex="0"
              @click="isSelected(false, 'is_public')"
              @keyup.enter="isSelected(false, 'is_public')"
              role="button"
              class="selection-tab"
              aria-label="Make non-public"
              :class="!this.program.is_public ? `active` : ``"
            >
              Partner Page Only
            </span>
            <span
              tabindex="0"
              @click="isSelected(true, 'is_public')"
              @keyup.enter="isSelected(true, 'is_public')"
              aria-label="Make public"
              role="button"
              class="selection-tab"
              :class="this.program.is_public ? `active` : ``"
            >
              Publish in Opportunities
            </span>
          </div>
          <span class="tooltip-container" style="align-self: center">
            <i class="fas fa-question-circle tooltip-icon left-margin-small" aria-hidden="true"></i>
            <span class="tooltip medium right" style="left: 39%; top: 100%">
              Choose if opportunity will be public on Opportunities page, or it will remain visible only inside Partner
              Account
            </span>
          </span>
        </div>
      </div>
      <div class="eu-form-section inup-form-section feature-section" style="">
        <h2 class="header-two-margin-bot" style="margin: 0; align-self: center">Status</h2>
        <div style="display: flex">
          <div class="selection-tabs-container">
            <span
              tabindex="0"
              @click="isSelected(true, 'is_published')"
              @keyup.enter="isSelected(true, 'is_published')"
              role="button"
              class="selection-tab"
              aria-label="Publish"
              :class="this.program.is_published ? `active` : ``"
            >
              Published
            </span>
            <span
              tabindex="0"
              @click="isSelected(false, 'is_published')"
              @keyup.enter="isSelected(false, 'is_published')"
              aria-label="UnPublish"
              role="button"
              class="selection-tab"
              :class="!this.program.is_published ? `active` : ``"
            >
              Draft
            </span>
          </div>
          <span class="tooltip-container" style="align-self: center">
            <i class="fas fa-question-circle tooltip-icon left-margin-small" aria-hidden="true"></i>
            <span class="tooltip medium right" style="left: 39%; top: 100%">
              Until opportunity is published, it's only visible in 'manage' section.
            </span>
          </span>
        </div>
      </div>
      <div
        v-if="isNewProgram && (partner || group || program.department)"
        class="eu-form-section inup-form-section feature-section"
        style=""
      >
        <h2 class="header-two-margin-bot" style="margin: 0; align-self: center">Share on Feed</h2>
        <div style="display: flex">
          <div class="selection-tabs-container">
            <span
              tabindex="0"
              @click="isSelected(true, 'share_on_feed')"
              @keyup.enter="isSelected(true, 'share_on_feed')"
              role="button"
              class="selection-tab"
              aria-label="Yes"
              :class="this.program.share_on_feed ? `active` : ``"
            >
              Yes
            </span>
            <span
              tabindex="0"
              @click="isSelected(false, 'share_on_feed')"
              @keyup.enter="isSelected(false, 'share_on_feed')"
              aria-label="No"
              role="button"
              class="selection-tab"
              :class="!this.program.share_on_feed ? `active` : ``"
            >
              No
            </span>
          </div>
          <span class="tooltip-container" style="align-self: center">
            <i class="fas fa-question-circle tooltip-icon left-margin-small" aria-hidden="true"></i>
            <span class="tooltip medium right" style="left: 39%; top: 100%">
              Check this if you want this to be shared on your Feed.
            </span>
          </span>
        </div>
      </div>
      <!-- <div class="eu-form-section inup-form-section" style="padding-bottom: 30px">
        <h2 class="header-two-margin-bot">For Internal Use</h2>
        <p>These items will only be visible to admin.</p>
        <div class="eu-form-element-container-no-bot-margin">
          <input class="inup-input" type="number" name="cost" v-model="program.budget_cost" />
          <div class="inup-form-label-container">
            <label for="cost">Opportunity Budget Cost</label>
          </div>
        </div>
        <div class="eu-form-element-container-no-bot-margin">
          <textarea
            name="goals"
            cols="40"
            rows="10"
            class="inup-textarea-input vertical"
            v-model="program.program_goals"
          >
          </textarea>
          <div class="inup-form-label-container">
            <label for="goals">Opportunity Goals</label>
          </div>
        </div>
      </div> -->
      <div class="inup-button-container">
        <button type="submit" class="eu-btn-primary-small" :class="loading ? 'disabled' : ''" :disabled="loading">
          {{ loading ? 'Saving...' : 'Save' }}
        </button>
        <a class="eu-btn-secondary-small" href="javascript:history.back()"> Cancel </a>
      </div>
    </form>
    <objects-select-modal
      :isModalActive="isAddContactModalActive"
      @close-modal="closeUsersSelectModal"
      title="Opportunity contacts"
      actionName="Add"
      objectName="User"
      :objectsList="contacts"
      :isLoading="isLoadingSelectUsers"
      :preselectedObjects="selectedUsers"
      :page="selectUserPage"
      :totalPageNumber="selectUserTotalPageNumber"
      :currentPage="selectUserCurrentPage"
      :showIcon="true"
    />
    <Notification @close="closeNotification" :isActiveProp="isNotificationActive" :notification="notification" />
  </div>
</template>
<script>
import ObjectsSelectModal from '../../accounts/components/modals/ObjectsSelectModal.vue';
import MultiselectComponent from '../../form/components/MultiselectComponent.vue';
import DropzoneComponent from '../../form/components/DropzoneComponent.vue';
import { validateExtensionFile } from '../../utils/TypecheckUtils';
import FeedService from '../../feed/services/FeedService';
import Notification from '../../components/Notification.vue';
import ProgramService from '../services/ProgramService';
import { Datetime } from 'vue-datetime';
import * as Cookies from 'js-cookie';
import moment from 'moment';
import { focusElement, isPartnerTenant } from '../../utils/CustomUtils';
import { majorLanguages } from '../../utils/constants';

export default {
  components: {
    MultiselectComponent,
    DropzoneComponent,
    Datetime,
    ObjectsSelectModal,
    Notification,
  },
  name: 'programs-manage',
  props: [
    'type',
    'departments',
    'identities',
    'focus_areas',
    'destinations',
    'audiences',
    'career_academic_interests',
    'image_size_data',
    'contacts',
    'state',
    'country',
    'program_form',
    'documents',
    'group',
    'partner',
    'isNewProgram',
    'deptSingular',
    'currencies',
  ],
  data() {
    return {
      program: {
        title: '',
        type: '',
        description: '',
        department: '',
        external_link: '',
        program_goals: '',
        cost: 0,
        is_featured: false,
        is_partner_featured: false,
        is_dashboard_featured: false,
        is_campus: false,
        address_1: '',
        city: '',
        address_2: '',
        postal_code: '',
        state: '',
        country: '',
        focus_areas: [],
        destinations: [],
        identities: [],
        audiences: [],
        thumbnail: '',
        documents: [],
        budget_cost: 0,
        start_date: '',
        end_date: '',
        time_zone: 'UTC',
        is_hero_banner: false,
        deadline: '',
        program_contacts: [],
        type_of_program_management: 'program',
        is_online: true,
        is_hybrid: false,
        online_url: '',
        is_published: true,
        is_public: true,
        share_on_feed: false,

        term: '',
        employment_type: '',
        internships_and_jobs_type: '',
        language_requirements: '',
        salary_amount: '',
        salary_currency: '',
        salary_frequency: '',
        salary_frequency_other: '',
        benefits: '',
        apply_url: '',
        contact_us_email_address: '',
      },
      contact_details: 'apply-url',
      language_requirements: [
        { name: 'English', value: 'English' },
        ...majorLanguages.map((lang) => ({ name: lang, value: lang })),
      ],
      preselected_destinations: [],
      preselected_identities: [],
      preselected_audiences: [],
      preselected_career_academic: [],
      preselected_language_requirements: [],
      thumbnail: {},
      headers: {
        'X-CSRFToken': Cookies.get('csrftoken'),
        Accept: 'application/json',
      },
      imageInput: null,
      files: [],
      dragging: false,
      error: '',
      error_url_message: '',
      selectedUsers: [],
      isAddContactModalActive: false,
      isLoadingSelectUsers: false,
      loading: false,
      selectUserPage: 1,
      selectUserTotalPageNumber: 1,
      selectUserCurrentPage: 1,
      isNotificationActive: false,
      notification: { msg: '', isError: false, icon: null },
      changeView: false,
      resource_counter: 0,
      isPartnerTenant: isPartnerTenant(),
      resource_url: {
        file_url: '',
        name: '',
        file_type: '',
        status: 'uploaded',
      },
      isAdmin: false,
      career_academic_interests_options: [],
    };
  },
  computed: {
    isGlobalProgram() {
      return this.program.type === 'Global Programs';
    },
    isJobIntershipProgram() {
      return this.program.type === 'Internships & Jobs';
    },
  },
  watch: {
    isGlobalProgram(newVal) {
      if (newVal === false) {
        this.program.term = '';
      }
    },
    isJobIntershipProgram(newVal) {
      if (newVal === false) {
        this.program.cost = 0;
        this.program.employment_type = '';
        this.program.internships_and_jobs_type = '';
        this.program.language_requirements = '';
        this.program.salary_amount = '';
        this.program.salary_currency = '';
        this.program.salary_frequency = '';
        this.salary_frequency_other = '';
        this.program.benefits = '';

        this.preselected_language_requirements = [];
      }

      if (newVal === true && this.program.salary_currency === '') {
        this.program.salary_currency = 'USD';
      }
    },
    'program.salary_frequency'(newVal) {
      if (newVal !== 'other') {
        this.salary_frequency_other = '';
      }
    },
    'program.type'(newVal) {
      console.log(newVal)
      if (newVal === 'Internships & Jobs') {
        this.program.external_link = '';
      }
    },
    // contact_details(newVal) {
    //   if (newVal !== 'apply-url' && this.program.apply_url) {
    //     this.program.apply_url = '';
    //   }
    //
    //   if (newVal !== 'contact-us-email-address' && this.program.contact_us_email_address) {
    //     this.program.contact_us_email_address = '';
    //   }
    // },
  },
  created() {
    this.career_academic_interests_options = this.career_academic_interests.map((cai) => {
      return { value: cai.id, name: cai.name };
    });
    this.initServices();
    this.initUserData();
    this.initProgramData();
    setTimeout(() => {
      this.imageInput = document.querySelector('#thumbnail');
    }, 0);
  },
  methods: {
    initServices() {
      this.service = new ProgramService();
      this.feedService = new FeedService();
    },
    initUserData() {
      const userStatusDataComponent = document.querySelector('.js-status-data');
      const loggedUser = JSON.parse(userStatusDataComponent.dataset.userStatus);
      this.isAdmin = loggedUser.user.role === 'ADMIN';
    },
    initProgramData() {
      if (!this.program_form) return;
      this.program = this.program_form[0];
      this.preselected_destinations = this.destinations.filter((e) => this.program.focus_areas.includes(e.value));

      this.program.destinations = this.preselected_destinations
        .filter((e) => this.program.focus_areas.includes(e.value))
        .map((e) => e.value);

      this.preselected_identities = this.identities.filter((e) => this.program.identities.includes(e.value));
      this.preselected_audiences = this.audiences.filter((e) => this.program.audiences.includes(e.value));
      this.preselected_career_academic = this.career_academic_interests_options.filter((e) =>
        this.program.career_academic_interests.includes(e.value)
      );
      this.selectedUsers = this.contacts.filter((e) => this.program.program_contacts.includes(e.id));

      if (this.program.language_requirements){
        this.preselected_language_requirements = this.language_requirements.filter((e) =>
          this.program.language_requirements.split(',').includes(e.value)
        );
      }

      if (this.program.apply_url) {
        this.contact_details = 'apply-url';
      }

      if (this.program.contact_us_email_address) {
        this.contact_details = 'contact-us-email-address';
      }

      if (this.documents.length !== 0) {
        this.files = this.documents;
      }

      if (this.program.start_date) {
        this.program.start_date = new Date(this.program.start_date).toISOString();
      }

      if (this.program.end_date) {
        this.program.end_date = new Date(this.program.end_date).toISOString();
      }
    },
    handleChange(value, name) {
      if (name === 'language_requirements') {
        this.program.language_requirements = value.map((v) => v.value).join(',');
        return;
      }

      this.program[name] = value.map((v) => v.value);
    },
    validateData() {
      let isValid = true;

      if (!this.program.deadline) {
        this.showNotification({
          msg: 'Please choose a deadline date.',
          isError: true,
          icon: 'fad fa-exclamation-circle',
        });
        isValid = false;
      }
      if (this.program.contact_us_email_address && this.program.apply_url) {
        this.showNotification({
          msg: 'Please select either "Apply URL" or "Contact US Email Address" option.',
          isError: true,
          icon: 'fad fa-exclamation-circle',
        });
        isValid = false;
      }

      return isValid;
    },
    async handleFileUploads() {
      const uploadedFiles = (this.program.documents || []).map((doc) => doc.file_url);
      const filesToUpload = this.files.filter((file) => !uploadedFiles.includes(file.file_url));
      if (!this.program.documents){
        this.program.documents = [];
      }
      const uploadPromises = filesToUpload.map((file) => this.uploadEachFile(file));
      return Promise.all(uploadPromises);
    },
    async uploadEachFile(file) {
      if (['uploaded', 'deleted'].includes(file.status)) {
        this.program.documents.push(file);
      }

      // Retry failed
      if (file.status === 'failed') {
        file.status = 'pending';
      }

      if (file.status === 'pending') {
        if (file.name.length > 0) {
          file.status = 'uploading';
          const res = await this.feedService.uploadFile(file, this.headers);

          if (res.success) {
            file.file_url = res.resource;
            file.status = 'uploaded';
            this.program.documents.push(file);
          } else {
            file.status = 'failed';
            this.error = `Upload failed for file ${file.name}: Server error.`;
            throw new Error(this.error);
          }
        } else {
          file.status = 'failed';
          this.error = `Upload failed for file ${file.name}: Required file details missing.`;
          throw new Error(this.error);
        }
      }
    },
    async createService() {
      this.program.focus_areas = this.program.focus_areas.concat(this.program.destinations);
      return this.service.create(this.program, this.headers);
    },
    redirectAfterCreate() {
      if (this.group || this.program.group) {
        window.location.href = `/groups/` + encodeURIComponent(`${this.group}`);
      } else if (this.partner || this.program.partner) {
        window.location.href = `/partners/` + encodeURIComponent(`${this.partner}`);
      } else {
        window.location.href = '/opportunities';
      }
    },
    async create() {
      if (!this.validateData() || this.loading) return;

      this.loading = true;

      try {
        await this.uploadImg();
        this.format_data();

        if (this.files.length !== 0) {
          await this.handleFileUploads();
        }

        await this.createService();
        this.redirectAfterCreate();
      } catch (e) {
        console.log(e)
        this.showNotification({ msg: 'Server Error', isError: true, icon: 'fad fa-exclamation-circle' });
        this.loading = false;
      }
    },
    prepareUpload(fileObject) {
      this.thumbnail = fileObject;
    },
    async uploadImg() {
      const hasThumbnail = Object.keys(this.thumbnail).length !== 0;
      const thumbnailChanged = this.thumbnail.fileUrl !== this.program.thumbnail;

      if (!hasThumbnail || !thumbnailChanged) return;

      const { resource } = await this.feedService.uploadFile(this.thumbnail, this.headers);

      this.program.thumbnail = resource;
      this.thumbnail.fileUrl = resource;
    },
    handleRemoveImage() {
      this.thumbnail = {};
      this.program.thumbnail = this.thumbnail;
    },
    handleFileUpload(event) {
      const uploadFiles = event.dataTransfer ? event.dataTransfer.files : event.target.files;
      const imageExt = ['jpg', 'jpeg', 'png', 'gif', 'webp'];
      const docExt = ['txt', 'pdf', 'doc', 'docx', 'xlsx', 'ppt', 'pptx'];
      if (!uploadFiles.length) return;
      for (let i = 0; i < uploadFiles.length; i++) {
        this.error = '';
        let file = {
          file_url: URL.createObjectURL(uploadFiles[i]),
          file: uploadFiles[i],
          name: uploadFiles[i].name,
          file_type: null,
          department: '',
          status: 'pending',
          error: null,
        };
        if (validateExtensionFile(uploadFiles[i].name, imageExt)) {
          file.file_type = 'IMG';
        } else if (validateExtensionFile(uploadFiles[i].name, docExt)) {
          file.file_type = 'DOC';
        } else {
          this.error = 'Please use a supported image filetype.';
          return;
        }

        if (!this.files.some((e) => e.name === file.name)) {
          this.files.push(file);
        } else {
          this.error = `File with name '${file.name}' already exists.`;
          return;
        }
      }
    },
    isSelected(selected, key) {
      this.program[key] = selected;
    },
    removeFile(file) {
      for (let f of this.files) {
        if (f.name === file.name) {
          f.status = 'deleted';
        }
      }
    },
    closeUsersSelectModal(data) {
      if (!data['canceled']) {
        this.selectedUsers = data['selectedObjects'];
        this.program.program_contacts = this.selectedUsers.map((x) => x.id);
      }
      this.isAddContactModalActive = false;
    },
    openAddContactModal() {
      this.isAddContactModalActive = true;
      focusElement('objects-select-modal-title');
    },
    isVirtual(value) {
      this.program.is_hybrid = false;
      this.program.is_online = value;
    },
    setIsHybrid(value) {
      this.program.is_online = !value;
      this.program.is_hybrid = value;
    },
    closeNotification() {
      this.isNotificationActive = false;
    },
    showNotification(notification) {
      this.isNotificationActive = true;
      this.notification = notification;
    },
    format_data() {
      if (this.group) this.program.group_name = this.group;
      if (this.partner) this.program.partner_name = this.partner;
      if (this.program.start_date) this.program.start_date = moment.utc(this.program.start_date).format('YYYY-MM-DD');
      if (this.program.end_date) this.program.end_date = moment.utc(this.program.end_date).format('YYYY-MM-DD');
      if (this.program.is_online) {
        this.program.city = null;
        this.program.address_1 = null;
        this.program.address_2 = null;
        this.program.state = null;
        this.program.postal_code = null;
      } else {
        this.program.online_url = null;
      }
    },
    addUrl() {
      this.error_url_message = '';

      if (this.resource_url.file_url === '' || this.resource_url.name === '' || this.resource_url.file_type === '') {
        this.error_url_message = 'Please fill all the fields in order to add resources.';
        return;
      }

      this.files.push(this.resource_url);
      ++this.resource_counter;

      this.resource_url = {
        file_url: '',
        name: '',
        file_type: '',
        status: 'uploaded',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.radio-btn-label {
  font-size: 13px;
  font-weight: 500;
  color: #4c55a7;

  span {
    font-weight: 600;
    color: #e65722;
    padding: revert;
  }
}

textarea[name='benefits'] {
  height: auto;
  min-height: auto;
}
</style>
