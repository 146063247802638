<template>
  <modal class="eu-file-upload-modal" :isActiveProp="isModalActive" @close="closeVideoModal">
    <div class="title-container">
      Embed Video
      <button
        @click="closeVideoModal()"
        @keyup.enter="closeVideoModal()"
        class="close-button"
        aria-label="Close video modal"
      >
        <i class="far fa-times"></i>
      </button>
    </div>
    <div v-if="isLoading">
      <loading-spinner />
    </div>
    <div style="display: contents" v-else>
      <form class="resource-form">
        <div class="small-dropzone-container">
          <small-dropzone-component
            :imageSrc="videoResource.thumbnail_url"
            :maxImageSize="2000000"
            :dialogDisplay="true"
            id="upload-video"
            maxImageSizeFormat="2 MB"
            changeButtonText="Change Thumbnail"
            uploaderText="Add Resource Thumbnail"
            @uploadImage="handleUploadThumbnail"
            @removeImage="handleRemoveThumbnail"
          />
        </div>
        <input-base
          id="video-url"
          type="text"
          label="Video Link"
          aria-label="Enter video url"
          maxlength="255"
          v-model="videoResource.video_url"
          :required="true"
          :value="videoResource.video_url"
        />
        <input-base
          id="file-name"
          type="text"
          label="File Name"
          aria-label="Enter video name"
          maxlength="255"
          v-model="videoResource.name"
          :required="true"
          :value="videoResource.name"
        />
        <div class="two-inputs-row">
          <select-base
            v-if="!currentDepartment && !currentGroup && !isPartnerTenant"
            id="file-department"
            label="Department"
            :placeholderDisabled="true"
            :options="
              list_departments.map((e) => {
                return {
                  label: e.name,
                  value: e.name,
                };
              })
            "
            v-model="videoResource.department"
            style="width: 100%"
            aria-label="Select department"
          >
          </select-base>
          <select-base
            id="file-type"
            label="Type/Label"
            :placeholderDisabled="true"
            :options="
              resourceLabelOptions.map((e) => {
                return {
                  label: e,
                  value: e,
                };
              })
            "
            v-model="videoResource.label"
            style="width: 100%"
            aria-label="Select label"
          >
          </select-base>
        </div>
        <div class="dialog-multiselect-wrapper">
          <multiselect
            id="focus-area"
            v-model="videoResource.focus_areas"
            :options="filterData.focus_areas.map((e) => e.name)"
            :multiple="true"
            :close-on-select="false"
            placeholder="Select option"
            :max="5"
          ></multiselect>
          <i class="fal fa-angle-down multiselect-arrow"></i>
          <label for="focus-area" class="multiselect-label">Focus Areas</label>
        </div>
        <div v-if="isPartnerTenant" class="dialog-multiselect-wrapper">
          <multiselect
            id="countries"
            v-model="videoResource.countries"
            :options="filterData.countries.map((e) => e.name)"
            :multiple="true"
            :close-on-select="false"
            placeholder="Select option"
            :max="5"
          ></multiselect>
          <i class="fal fa-angle-down multiselect-arrow"></i>
          <label for="countries" class="multiselect-label">Destinations</label>
        </div>
        <div class="dialog-multiselect-wrapper">
          <multiselect
            id="identity"
            v-model="videoResource.identities"
            :options="filterData.identities.map((e) => e.name)"
            :multiple="true"
            :close-on-select="false"
            placeholder="Select option"
            :max="5"
          ></multiselect>
          <i class="fal fa-angle-down multiselect-arrow"></i>
          <label for="focus-area" class="multiselect-label">Identities</label>
        </div>
        <div class="dialog-multiselect-wrapper">
          <multiselect
            id="audience"
            v-model="videoResource.audiences"
            :options="filterData.audiences.map((e) => e.name)"
            :multiple="true"
            :close-on-select="false"
            placeholder="Select option"
            :max="5"
          ></multiselect>
          <i class="fal fa-angle-down multiselect-arrow"></i>
          <label for="focus-area" class="multiselect-label">Audiences</label>
        </div>
        <div v-if="isPartnerTenant" class="dialog-multiselect-wrapper">
          <multiselect
            id="cai"
            v-model="videoResource.career_academic_interests"
            :options="filterData.career_academic_interests.map((e) => e.name)"
            :multiple="true"
            :close-on-select="false"
            placeholder="Select option"
            :max="5"
          ></multiselect>
          <i class="fal fa-angle-down multiselect-arrow"></i>
          <label for="career-academic-interests" class="multiselect-label">Career Academic Interests</label>
        </div>
        <div style="position: relative">
          <textarea
            id="description"
            v-model="videoResource.description"
            type="text"
            rows="4"
            class="modal-textarea"
            maxlength="150"
          />
          <label for="description" class="multiselect-label">Description</label>
        </div>
        <div class="modal-checkbox-container">
          <label for="feature-resource-video-modal" style="width: unset" class="eu-checkbox-container">
            Featured resource
            <input
              v-if="isPartnerTab"
              id="feature-resource-video-modal"
              type="checkbox"
              v-model="videoResource.is_partner_featured"
            />
            <input else id="feature-resource-video-modal" type="checkbox" v-model="videoResource.featured" />
            <span class="eu-checkmark"></span>
            <span class="eu-checkmark-tooltip">
              <span class="link">
                <i class="fad fa-question-circle"></i>
                <div class="eu-resource-desc" style="right: unset; top: -33px">
                  <span class="eu-resource-desc-left-arrow"></span>
                  <span class="eu-resource-desc-el">
                    <p class="eu-resource-desc-text">
                      Check this box if you want this file to be highlighted in your students’ resource library.
                    </p>
                  </span>
                </div>
              </span>
            </span>
          </label>
        </div>
        <div class="dialog-error-container">
          <p class="error">{{ error }}</p>
        </div>
        <div class="dialog-actions-container">
          <button @click.prevent="embedVideo" class="iu-main-btn small main solid" aria-label="Upload video.">
            Save
          </button>
          <button
            @click.prevent="closeVideoModal"
            class="iu-main-btn small gray outlined"
            aria-label="Cancel uploading video."
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  </modal>
</template>

<script lang="ts">
import Modal from "../../../components/ModalExtended.vue";
import Multiselect from "vue-multiselect";
import { validateResourceInput } from "../../../utils/TypecheckUtils";
import Cookies from "js-cookie";
import { resourceLabelOptions } from "../../../utils/constants";
import ResourceLibraryService from "../../services/ResourceLibraryService";
import FeedService from "../../../feed/services/FeedService";
import SmallDropzoneComponent from "../../../form/components/SmallDropzoneComponent.vue";
import InputBase from "../../../components/formBase/InputBase.vue";
import SelectBase from "../../../components/formBase/SelectBase.vue";
import LoadingSpinner from "../../../components/LoadingSpinner.vue";

export default {
  name: "upload-video-modal",
  components: { Modal, Multiselect, SmallDropzoneComponent, InputBase, SelectBase, LoadingSpinner },
  props: [
    "showShareBtn",
    "isModalActive",
    "currentDepartment",
    "list_departments",
    "filterData",
    "department_name",
    "currentGroup",
    "isPartnerTenant",
    "currentPartner",
    "isPartnerTab",
  ],

  data() {
    return {
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        Accept: "application/json",
      },
      isLoading: false,
      videoResource: {
        name: "",
        description: "",
        department: null,
        focus_areas: "",
        countries: "",
        identities: "",
        audiences: "",
        career_academic_interests: "",
        group: "",
        label: resourceLabelOptions[0],
        featured: false,
        video_url: "",
        file_type: "VID",
        is_partner_featured: false,
        thumbnail_url: "",
        thumbnail: {},
      },
      resourceLabelOptions,
      isUrlModalActive: false,
      editFile: null,
      sharePostOnFeed: true,
      error: "",
      thumbnail_changed: false,
      thumbnail_deleted: false,
    };
  },

  created() {
    this.service = new ResourceLibraryService();
    this.feedService = new FeedService();
    if (!this.isAdminRole) this.videoResource.department = this.department_name ? this.department_name : null ;
  },

  methods: {
    closeVideoModal() {
      this.$emit("close-video-modal");
      this.clearForm();
    },

    async embedVideo(): Promise<void> {
      if (this.isLoading) return;
      this.isLoading = true;
      if (this.videoResource.thumbnail && this.thumbnail_changed && !this.thumbnail_deleted) {
        const thumbnailRes = await this.feedService.uploadFile(this.videoResource.thumbnail, this.headers);
        if (thumbnailRes && thumbnailRes.success) {
          this.videoResource.thumbnail_url = thumbnailRes.resource;
        } else {
          this.error = "Something went wrong, please contact our support.";
        }
      }
      if (this.currentDepartment) {
        this.videoResource.department = this.currentDepartment;
      }
      if (this.currentGroup) {
        this.videoResource.group = this.currentGroup;
      }
      if (this.currentPartner) {
        this.videoResource.partner = this.currentPartner;
      }
      const validationRes = validateResourceInput(this.videoResource);
      if (!validationRes.isValid) {
        this.error = validationRes.err;
        this.isLoading = false;
        return;
      }
      const res = await this.service.postFormData(this.videoResource, this.headers);
      if (this.videoResource) {
        if (res.success) {
          this.createFeedPostForVideo(res.resource);
          this.clearForm();
          this.closeVideoModal();
        } else {
          this.error = "Server error";
        }
      }
      this.isLoading = false;
    },

    handleUploadThumbnail(fileObject) {
      this.thumbnail_changed = true;
      this.thumbnail_deleted = false;
      this.videoResource.thumbnail = fileObject;
      this.videoResource.thumbnail_url = fileObject.fileUrl;
    },

    handleRemoveThumbnail() {
      this.thumbnail_deleted = true;
      this.videoResource.thumbnail = {};
    },

    async createFeedPostForVideo(videoFile) {
      if (this.sharePostOnFeed) {
        const post = { files: [videoFile.id] };
        if (this.currentGroup) {
          post["text"] = !!videoFile.description
            ? videoFile.description
            : `New video has been embedded into ${this.currentGroup}.`;
          post["group"] = this.currentGroup;
          await this.feedService.createGroupPost(post, this.headers);
        } else if (this.currentPartner) {
          post["text"] = !!videoFile.description
            ? videoFile.description
            : `New video has been embedded into ${this.currentPartner}.`;
          post["partner"] = this.currentPartner;
          await this.feedService.createPartnerPost(post, this.headers);
        } else {
          post["text"] = !!videoFile.description
            ? videoFile.description
            : "New video has been embedded into Community Library.";
          post["department"] = videoFile.department.name;
          await this.feedService.createPost(post, this.headers);
        }
      }
      this.reloadResources();
    },

    clearForm() {
      this.videoResource = {
        name: "",
        description: "",
        department: null,
        focus_areas: "",
        countries: "",
        identities: "",
        career_academic_interests: "",
        featured: false,
        is_partner_featured: false,
        label: resourceLabelOptions[0],
        video_url: "",
        file_type: "VID",
        thumbnail_url: "",
        thumbnail: {},
      };
      this.thumbnail_changed = false;
      this.thumbnail_deleted = false;
    },

    reloadResources() {
      this.$emit("reloadResources");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-container {
  width: 600px;
}

.title-container {
  width: 100%;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  color: #444444;
  line-height: 1.2;
  position: relative;
}

.close-button {
  color: #95919b;
  font-size: 24px;
  border: none;
  background-color: transparent;
  position: absolute;
  right: 0;
  top: 0;

  &:hover {
    cursor: pointer;
    color: #88858e;
  }
}

.resource-form {
  width: 100%;
  margin-top: 24px;
  display: grid;
  gap: 16px;
}

.multiselect-arrow {
  position: absolute;
  top: 14px;
  z-index: 2;
  right: 12px;
  color: #685df3;
  font-size: 15px;
  font-weight: 600;
  pointer-events: none;

  &:hover {
    cursor: pointer;
    color: #9f87e0;
  }
}

.multiselect-label {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.15;
  color: #3b3355;
  background-color: #fff;
  padding: 0 6px 2px;
  position: absolute;
  left: 12px;
  top: -8px;
  width: unset !important;
}

.dialog-multiselect-wrapper {
  position: relative;
  cursor: pointer;
}

.modal-textarea {
  padding: 12px 0 0 12px;
  font-size: 16px;
  border-radius: 6px;
  border: 1px solid #949494;
  resize: none;
  background-color: #fff;

  &:active {
    border-color: #685df3;
  }

  &:focus {
    border-color: #685df3;
  }
}

.two-inputs-row {
  display: flex;
  width: 100%;
  gap: 8px;
  overflow: visible;
}

.dialog-error-container {
  height: 12px;
  color: #f16b4c;
  font-size: 12px;
}

.dialog-actions-container {
  display: flex;
  justify-content: center;
  gap: 12px;
}

.modal-checkbox-container {
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
  justify-content: center;
}

@media screen and (max-width: 632px) {
  .modal-checkbox-container {
    gap: 16px;
  }
  .modal-container {
    width: 100%;
    margin: 0 16px;
  }

  .two-inputs-row {
    flex-direction: column;
  }
}
</style>

<style lang="scss">
.dialog-multiselect-wrapper {
  .multiselect__tags {
    border-width: 1px;
    border-radius: 6px;
  }
}
</style>
